import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  card: {
    marginTop: '5px',
    padding: '30px',
    scrollPaddingBlockEnd: true,
    marginBottom: '20px',
    spacing: '10px',
  },
  button: {
    paddingLeft: '10px',
    marginLeft: '10px',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'red',
  },
});

const useStyles = makeStyles({
  media: {
    marginTop: '60px',
    height: '400px',
  },
  main: {
    backgroundColor: 'black',
  },
  form: {
    marginTop: '10px',
    backgroundColor: 'black',
  },
  mainBreakdownItem: {
    color: 'darkorange',
  },
  mainCardContent: {
    marginTop: '5px',
    padding: '30px',
  },
  mainEventCard: {
    padding: '10px',
    scrollPaddingBlockEnd: true,
  },
});

export class FormPersonalDetails extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    const { values, handleChange, classes } = this.props;
    return (
      <MuiThemeProvider>
        <>
          <Grid>
            <Card className={classes.card}>
              {/* <Dialog
            open
            fullWidth
            maxWidth='sm'
          >
            <AppBar title="Enter Event Details" /> */}
              <TextField
                placeholder="Enter Event Detail Information"
                label="Event Detail Information"
                onChange={handleChange('eventAdditionalInfo')}
                defaultValue={values.eventAdditionalInfo}
                margin="normal"
                fullWidth
              />
              <br />
              <TextField
                placeholder="Enter Your City"
                label="City"
                onChange={handleChange('eventCity')}
                defaultValue={values.eventCity}
                margin="normal"
                fullWidth
              />
              <br />
              <TextField
                placeholder="Enter Your Event State"
                label="Event State"
                onChange={handleChange('eventState')}
                defaultValue={values.eventState}
                margin="normal"
                fullWidth
              />
              <br />
              <TextField
                placeholder="Enter Your Event Zip"
                label="Event Zip"
                onChange={handleChange('eventZip')}
                defaultValue={values.eventZip}
                margin="normal"
                fullWidth
              />
              <br />
              <TextField
                placeholder="Enter Your Event Country"
                label="Event Country"
                onChange={handleChange('eventCountry')}
                defaultValue={values.eventCountry}
                margin="normal"
                fullWidth
              />
              <br />
            </Card>
          </Grid>
          <Button color="secondary" variant="contained" onClick={this.back}>
            Back
          </Button>

          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={this.continue}
          >
            Continue
          </Button>
          {/* </Dialog> */}
        </>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(FormPersonalDetails);
