import axios from 'axios';

const API_URL =
  'https://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/auth/';

const register = (username, email, password) => {
  return axios.post(API_URL + 'register', {
    username,
    email,
    password,
  });
};

const forgotPassword = (email) => {
  return axios
    .put(API_URL + 'forgot-password', {
      email,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return 'Click the link in your email to reset your password.';
    });
};
const resetPassword = (resetLink, newPass) => {
  return axios
    .put(API_URL + 'reset-password', {
      resetLink,
      newPass,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return 'Password reset complete';
    });
};

const login = (username, password) => {
  return axios
    .post(API_URL + 'login', {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    });
};

const logout = () => {
  localStorage.removeItem('user');
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'));
};

const verifyUser = (code) => {
  return axios.get(API_URL + 'confirm/' + code).then((response) => {
    return response.data;
  });
};

export default {
  register,
  login,
  logout,
  getCurrentUser,
  verifyUser,
  forgotPassword,
  resetPassword,
};
