// import logo from './logo.svg';
// import './App.css';

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
// import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { CssBaseline, Container } from '@material-ui/core';
import Footer from './components/layouts/Footer_';
import NavigationBar from '../src/components/layouts/NavigationBar_';
import LoginPage from './pages/LoginPage/LoginPage';
import Login from './components/Login';
import Welcome from './components/Welcome';
import ConfirmAccount from './components/ConfirmAccount';
import Home from './components/Home';
import Profile from './components/Profile';
import BoardUser from './components/BoardUser';
import BoardModerator from './components/BoardModerator';
import BoardAdmin from './components/BoardAdmin';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import ResetPassword from './components/ResetPassword';
import RegisterPage from './pages/RegisterPage/RegisterPage';
import AlertNotification from './shared/components/AlertNotification';
import EventDetail from './components/EventDetail';
import EventForm from '../src/components/EventForm/EventForm';
import ShowEventDetails_ from './components/ShowEventDetails_';
import ShowEventList_ from './components/ShowEventList_';
import BililiEventPolitique from './components/BililiEventPolitique';
import BililiEventAboutUs from './components/BililiEventAboutUs';
import UpdateEventInfo_ from './components/UpdateEventInfo_';
import ProfileUpdate from './components/ProfileUpdate';
import { Profiler } from 'react';

function App() {
  // const currentUser = true;
  return (
    <>
      <div className="App">
        <CssBaseline />
        <NavigationBar />
        <Container>
          <main>
            <Router>
              <Routes>
                <Route exact path="/home" element={<ShowEventList_ />} />
                <Route exact path="/register" element={<Register />} />
                <Route
                  exact
                  path="/forgot-password"
                  element={<ForgotPassword />}
                />
                {/* <Route exact path="/register" element={<RegisterPage />} /> */}
                {/* <Route exact path="/login" element={<LoginPage />} /> */}
                <Route exact path="/login" element={<Login />} />
                <Route exact path="/dashboard" element={<EventForm />} />
                <Route exact path="/eventDetail" element={<EventDetail />} />
                <Route
                  exact
                  path="/create%20an%20event"
                  element={<EventForm />}
                />
                <Route exact path="/" element={<ShowEventList_ />} />
                <Route
                  exact
                  path="/confirm/:confirmationCode"
                  element={<Welcome />}
                />
                <Route
                  exact
                  path="/resetpassword/:confirmationCode"
                  element={<ResetPassword />}
                />
                {/* <Route path="/create-event" element={<CreateEvent />} /> */}
                <Route path="/create-event" element={<EventForm />} />
                <Route path="/edit-event/:id" element={<UpdateEventInfo_ />} />
                <Route path="/edit-user/:id" element={<ProfileUpdate />} />
                <Route path="/show-event/:id" element={<ShowEventDetails_ />} />
                <Route path="/show-user/:id" element={<Profile />} />
                <Route
                  path="/datamanagement"
                  element={<BililiEventPolitique />}
                />
                <Route path="/aboutus" element={<BililiEventAboutUs />} />
                <Route exact path={('/', '/home')} element={<Home />} />
                {/* <Route exact path="/login" element={<Login />} />
                <Route exact path="/register" element={<Register />} /> */}
                <Route exact path={'/profile'} element={<Profile />} />
                <Route path="/user" element={<BoardUser />} />
                <Route path="/mod" element={<BoardModerator />} />
                <Route path="/admin" element={<BoardAdmin />} />
                <Route
                  path="/confirm/:confirmationCode"
                  element={<Welcome />}
                />
                <Route path="/confirm" element={<ConfirmAccount />} />
              </Routes>
            </Router>
            <AlertNotification />
          </main>
        </Container>

        <Footer />
      </div>
    </>
  );
}

export default App;
