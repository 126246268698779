import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';
import AuthBox from '../../shared/components/AuthBox';
import RegisterPageInputs from './RegisterPageInputs';
import RegisterPageFooter from './RegisterPageFooter';
import { validateRegisterForm } from '../../shared/utils/validators';
import { connect } from 'react-redux';
import { getActions } from '../../store/actions/authActions';
import { useNavigate } from 'react-router-dom';
import { CssBaseline, Container } from '@material-ui/core';
import NavigationBar from '../../components/layouts/NavigationBar';
import Footer from '../../components/layouts/Footer_';

const RegisterPage = ({ register }) => {
  const navigate = useNavigate();

  const [email, setMail] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const [isFormValid, setIsFormValid] = useState(false);

  const handleRegister = () => {
    const userDetails = {
      email,
      password,
      username,
    };

    register(userDetails, navigate);
  };

  useEffect(() => {
    setIsFormValid(
      validateRegisterForm({
        email,
        username,
        password,
      })
    );
  }, [email, username, password, setIsFormValid]);

  return (
    <div className="App">
      <CssBaseline />
      <NavigationBar />
      <Container>
        <main>
          <AuthBox>
            <Typography variant="h5" sx={{ color: 'white ' }}>
              Create an account
            </Typography>
            <RegisterPageInputs
              email={email}
              setMail={setMail}
              username={username}
              setUsername={setUsername}
              password={password}
              setPassword={setPassword}
            />
            <RegisterPageFooter
              handleRegister={handleRegister}
              isFormValid={isFormValid}
            />
          </AuthBox>
        </main>
      </Container>

      <Footer />
    </div>
  );
};

const mapActionsToProps = (dispatch) => {
  return {
    ...getActions(dispatch),
  };
};

export default connect(null, mapActionsToProps)(RegisterPage);
