import React, { Component } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
// import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { withStyles } from '@material-ui/core/styles';

// import React, { Component } from 'react';
import DatePicker from 'react-datepicker';

import 'react-datepicker/dist/react-datepicker.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

const eventTypes = [
  {
    value: 'Music',
    label: 'Music',
  },
  {
    value: 'Health',
    label: 'Health',
  },
  {
    value: 'Sport',
    label: 'Sport',
  },
  {
    value: 'Religion',
    label: 'Religion',
  },
];
const eventCost = [
  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Payable',
    label: 'Payable',
  },
  {
    value: 'Surprise',
    label: 'Surprise',
  },
];

const styles = (theme) => ({
  card: {
    marginTop: '5px',
    padding: '30px',
    scrollPaddingBlockEnd: true,
    marginBottom: '20px',
    spacing: '10px',
  },
  button: {
    // paddingLeft: "10px",
    marginLeft: '10px',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'red',
  },
});

// const ToggleableText = ({ input, fwd }) => (
//   <div style={{ display: eventCost ? 'block' : 'none' }}>
//     <input type="text" {...input} placeholder="Fwd dependent" />
//   </div>
// );

export class FormEventCostDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventDate: new Date(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.onFormSubmit = this.onFormSubmit.bind(this);
  }
  handleChange(date) {
    this.setState({
      eventDate: date,
    });
  }
  onFormSubmit(e) {
    e.preventDefault();
    console.log(this.state.eventDate);
  }

  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  render() {
    // const [currency, setCurrency] = React.useState('EUR');
    const { values, handleChange, classes } = this.props;
    return (
      <MuiThemeProvider>
        <>
          <Grid>
            <Card className={classes.card}>
              <TextField
                select
                placeholder="Enter Your Event Type"
                label="Event Type"
                onChange={handleChange('eventType')}
                defaultValue={values.eventType}
                margin="normal"
                fullWidth
              >
                {eventTypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              <br />
              {/* <TextField
                placeholder="Enter Your Event Date"
                label="Event Date"
                onChange={handleChange('eventDate')}
                defaultValue={values.eventDate}
                margin="normal"
                fullWidth
              /> */}
              <br />
              <div className="form-group">
                <input
                  type="date"
                  placeholder="Enter Your Event Date"
                  label="Event Date"
                  name="eventDate"
                  className="form-control"
                  value={values.eventDate}
                  onChange={handleChange('eventDate')}
                />
              </div>
              <br />
              <div className="form-group">
                <input
                  type="time"
                  placeholder="Enter Your Event Time"
                  label="Event Time"
                  name="eventTime"
                  className="form-control"
                  value={values.eventTime}
                  onChange={handleChange('eventTime')}
                />
              </div>
              <br />
              {/* <div className="form-group">
                <input
                  type="date"
                  placeholder="published_date"
                  name="published_date"
                  className="form-control"
                  value={values.eventDate}
                  onChange={handleChange('eventDate')}
                />
              </div> */}
              {/* <div className="form-group">
                <DatePicker
                  placeholder="Enter Your Event Time"
                  name="eventDate"
                  selected={this.state.eventDate}
                  onChange={this.handleChange}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  timeCaption="time"
                  dateFormat="MMMM d, yyyy h:mm aa"
                />
              </div> */}
              <br />
              {/* <TextField
                placeholder="Enter Your Event Time"
                label="Event Time"
                onChange={handleChange('eventTime')}
                defaultValue={values.eventTime}
                margin="normal"
                fullWidth
              />
              <br /> */}
              <TextField
                select
                placeholder="Enter Your Event Cost"
                label="Event Cost"
                onChange={handleChange('eventCost')}
                defaultValue={values.eventCost}
                margin="normal"
                name="eventCost"
                fullWidth
              >
                {eventCost.map((option) => (
                  <MenuItem
                    key={option.value}
                    // selected={option === 'FREE'}
                    value={option.value}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
              {/* <br /> */}
              {/* {console.log(values.eventCost)} */}
              <br />
              <div
                style={{
                  display: values.eventCost !== 'Free' ? 'block' : 'none',
                }}
              >
                <TextField
                  // Component={ToggleableText}
                  placeholder="Enter Your Event Cost Amount"
                  label="Event Cost Amount"
                  onChange={handleChange('eventCostAmount')}
                  defaultValue={values.eventCostAmount}
                  margin="normal"
                  fullWidth
                />
              </div>

              <br />
              <TextField
                placeholder="Enter Your Event Location"
                label="Event Location"
                onChange={handleChange('eventLocation')}
                defaultValue={values.eventLocation}
                margin="normal"
                fullWidth
              />
              <br />
              <TextField
                placeholder="Enter Your Event Address"
                label="Event Address"
                onChange={handleChange('eventAddress')}
                defaultValue={values.eventAddress}
                margin="normal"
                fullWidth
              />
              <br />
            </Card>
          </Grid>
          <Button color="secondary" variant="contained" onClick={this.back}>
            Back
          </Button>

          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={this.continue}
          >
            Continue
          </Button>
        </>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(FormEventCostDetails);
