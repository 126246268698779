import React from 'react';
import InputWithLabel from '../../shared/components/InputWithLabel';

const LoginPageInputs = ({ email, setMail, password, setPassword }) => {
  return (
    <>
      <InputWithLabel
        value={email}
        setValue={setMail}
        label="email"
        type="text"
        placeholder="Enter email address"
      />
      <InputWithLabel
        value={password}
        setValue={setPassword}
        label="Password"
        type="password"
        placeholder="Enter password"
      />
    </>
  );
};

export default LoginPageInputs;
