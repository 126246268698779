import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import { Link } from 'react-router-dom';
import GridItem from './layouts/GridItem_';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import EventGridBanner from '../components/layouts/EventGridBanner';
import AuthService from '../services/auth.service';

const useStyles = makeStyles((theme) => ({
  eventGridItem: {
    backgroundColor: 'grey',
  },
  eventBreakdownItem: {
    color: 'white',
  },
  eventGrid: {
    backgroundColor: 'grey',
    marginTop: 50,
  },
}));

const currentUser = AuthService.getCurrentUser();

class ShowEventList_ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
    };
  }

  componentDidMount() {
    // console.log('TEST 1');
    // async function alerting() {
    //   console.log('TEST 2');
    //   let url =
    //     'http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events';
    //   let response = await fetch(url);

    //   let commits = await response.json(); // read response body and parse as JSON
    //   console.log('TEST 3');
    //   console.log(response);
    //   console.log(commits);

    //   // alert(commits[0].author.login);
    // }

    // alerting();
    axios
      .get(
        'http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events',
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            // Authorization: `Bearer ${currentUser.accessToken}`,git
          },
        }
      )
      .then((res) => {
        this.setState({
          events: res.data,
        });
        console.log(res);
      })
      .catch((err) => {
        console.log('Error from ShowEventList');
      });
  }

  render() {
    const { classes } = this.props;
    const events = this.state.events;
    // console.log('PrintEvent: ' + events);
    let eventList;

    if (!events) {
      eventList = 'there is no event recorded!';
    } else {
      eventList = events.map((event, k) => (
        <Grid item lg={4} md={6} sm={12} xs={12}>
          <GridItem event={event} key={k} />
        </Grid>
      ));
    }

    return (
      <Grid container spacing={3} className={classes.eventGrid}>
        <Grid item lg={12} sm={12} xs={12}>
          <Typography className={classes.eventGridItem}>
            <EventGridBanner />
          </Typography>
          {currentUser ? (
            <Link
              to="/create-event"
              className="btn btn-outline-warning float-right"
            >
              + Add New Event
            </Link>
          ) : (
            ''
          )}

          <br />
          <br />
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            className={classes.eventBreakdownItem}
            style={{ color: 'white' }}
            variant="h2"
          >
            Main Events
          </Typography>
          {/* <h2 className="display-4 text-center ">Main Events</h2> */}
        </Grid>
        {/* <Grid item lg={4} md={12} sm={12} xs={12}> */}
        {eventList}
        {/* </Grid> */}
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography
            className={classes.eventBreakdownItem}
            style={{ color: 'white' }}
            variant="h2"
          >
            Local Events
          </Typography>
          {/* <h2 className="display-4 text-center">Local Events</h2> */}
        </Grid>
        {/* <Grid item lg={4} md={6} sm={12} xs={12}> */}
        {eventList}
        {/* </Grid> */}
      </Grid>
    );
  }
}

// export default ShowEventList_;
export default withStyles(useStyles)(ShowEventList_);
