import React from 'react';
import AuthService from '../services/auth.service';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import { red } from "@material-ui/core/colors";
import { Card, Grid, withStyles } from '@material-ui/core';
// import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from '@material-ui/core/CardActions';
// import { red } from "@material-ui/core/colors";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import { makeStyles } from '@material-ui/core/styles';

import axios from 'axios';

const useStyles = makeStyles({
  media: {
    marginTop: '60px',
    height: '100px',
    width: '100px',
    // resize: { width: 50, height: 50 },
  },
  main: {
    backgroundColor: 'black',
    width: '80%',
    justifyContent: 'center',
    margin: 'auto',
  },
});

const Profile = (props) => {
  const classes = useStyles();
  const [profilePic, setProfilePic] = React.useState(null);
  const currentUser = AuthService.getCurrentUser();
  const _id = currentUser.id;
  console.log('This is the current user ID:.......' + _id);

  React.useEffect(() => {
    axios
      .get(
        'http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/users/' +
          _id,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then((response) => {
        setProfilePic(response.data);
      });
  }, []);

  if (!profilePic) return null;

  function onDeleteClick() {
    const home = () => {
      window.open('/', '_self');
    };
    const logOut = () => {
      AuthService.logout();
    };
    axios
      .delete(
        'http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/users/' +
          _id,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${currentUser.accessToken}`,
          },
        }
      )
      .then((res) => {
        // console.log('Deteled from Profile_deleteClick user with id: ' + _id);
        logOut();
        home();
      })
      .catch((err) => {
        console.log('Error form Profile_deleteClick');
      });
  }

  // if (props.match.path === '/confirm/:confirmationCode') {
  //   AuthService.verifyUser(props.match.params.confirmationCode);
  // }

  return (
    <Card style={{ backgroundColor: 'black', margin: 'auto' }}>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className={classes.main}
        // style={{ justifyContent: 'right', display: 'flex' }}
      >
        <CardMedia
          className={classes.media}
          component="img"
          image={profilePic.images}
          // image={console.log(post.images)}
          // image="https://bilili.s3.us-east-2.amazonaws.com/bililiuserprofile-1658469252931-cf2bc29a-a00b-44da-a443-75d8481669ee.png"
          // title={currentUser.eventTitle}
        />
        <CardContent>
          <div>
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              style={{ justifyContent: 'center', color: 'white' }}
            >
              <strong>{currentUser.username}</strong> Profile
            </Typography>
            <table className="table table-hover table-dark">
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td variant="h6">
                    <strong>Token:</strong>
                  </td>
                  <td>
                    {currentUser.accessToken.substring(0, 20)} ...{' '}
                    {currentUser.accessToken.substr(
                      currentUser.accessToken.length - 20
                    )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td variant="h6">
                    <strong>Id:</strong>
                  </td>
                  <td>{currentUser.id}</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td variant="h6">
                    <strong>Email:</strong>
                  </td>
                  <td>{currentUser.email}</td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td variant="h6">
                    <strong>Status:</strong>
                  </td>
                  <td>{currentUser.status}</td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td variant="h6">
                    <strong>Authorities:</strong>
                  </td>
                  <td>
                    {currentUser.roles &&
                      currentUser.roles.map((role, index) => (
                        <li key={index}>{role}</li>
                      ))}
                  </td>
                </tr>
              </tbody>
            </table>
            <CardActions style={{ justifyContent: 'center' }}>
              <IconButton
                aria-label="add to favorites"
                style={{ color: 'white' }}
              >
                <FavoriteIcon />
              </IconButton>
              <IconButton aria-label="share" style={{ color: 'white' }}>
                <ShareIcon />
              </IconButton>
              <Button
                size="small"
                color="primary"
                type="button"
                className="btn btn-outline-danger btn-lg btn-block"
                onClick={onDeleteClick.bind(this, currentUser._id)}
              >
                Delete User Profile
              </Button>
              <Link
                to={`/edit-user/${currentUser.id}`}
                className="btn btn-outline-info btn-lg btn-block"
              >
                Edit User Profile
              </Link>
            </CardActions>
          </div>
        </CardContent>
      </Grid>
    </Card>
  );
};

// export default Profile;
export default withStyles(useStyles)(Profile);
