import React, { useState, useEffect } from 'react';
import AuthBox from '../../shared/components/AuthBox';
import LoginPageFooter from './LoginPageFooter';
import LoginPageHeader from './LoginPageHeader';
import LoginPageInputs from './LoginPageInputs';
import { validateLoginForm } from '../../shared/utils/validators';
import { connect } from 'react-redux';
import { getActions } from '../../store/actions/authActions';
import { useNavigate } from 'react-router-dom';
import { CssBaseline, Container } from '@material-ui/core';
import NavigationBar from '../../components/layouts/NavigationBar';
import Footer from '../../components/layouts/Footer_';

const LoginPage = ({ login }) => {
  const navigate = useNavigate();

  const [email, setMail] = useState('');
  const [password, setPassword] = useState('');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    setIsFormValid(validateLoginForm({ email, password }));
  }, [email, password, setIsFormValid]);

  const handleLogin = () => {
    const userDetails = {
      email,
      password,
    };

    login(userDetails, navigate);
  };

  return (
    <div className="App">
      <CssBaseline />
      <NavigationBar />
      <Container>
        <main>
          <AuthBox>
            <LoginPageHeader />
            <LoginPageInputs
              email={email}
              setMail={setMail}
              password={password}
              setPassword={setPassword}
            />
            <LoginPageFooter
              isFormValid={isFormValid}
              handleLogin={handleLogin}
            />
          </AuthBox>
        </main>
      </Container>

      <Footer />
    </div>
  );
};

const mapActionsToProps = (dispatch) => {
  return {
    ...getActions(dispatch),
  };
};

export default connect(null, mapActionsToProps)(LoginPage);
