import {
  Typography,
  makeStyles,
  CardMedia,
  CardContent,
  CardActions,
  Card,
  CardHeader,
  IconButton,
  Container,
} from '@material-ui/core';
import { ArrowForward } from '@material-ui/icons';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  cardImage: {
    height: 300,
    backgroundImage:
      'url(https://images.pexels.com/photos/1190298/pexels-photo-1190298.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1)',
    color: theme.palette.warning.dark,
    //color: theme.palette.common.white,
    //backgroundSize: 'cover'
  },
  cardCss: {
    backgroundColor: 'grey',
    // display: grid,
    margin: '20px 0 50px 0',
    // // display: "grid",
    // // margin: 20px,
    // gridTemplateColumns: 'repeat(4, 1fr)',
    // gridAutoRows: '1fr',
    // gridGap: '2em',
  },
  cardHeaderCss: {
    color: 'white',
  },
  cardDescriptionCss: {
    color: 'white',
  },
  cardSubHeaderCss: {
    color: 'black',
  },
  cardIconCss: {
    color: 'black',
    align: 'right',
  },
}));

export default function GridItem(props) {
  const event = props.event;
  const classes = useStyles();

  return (
    <Card className={classes.cardCss}>
      <CardMedia
        className={classes.cardImage}
        image={event.images}
        title={event.eventOrganizerName}
      />
      <CardHeader
        className={classes.cardHeaderCss}
        // title={event.eventTitle}
        title={<Link to={`/show-event/${event._id}`}>{event.eventTitle}</Link>}
        subheader={
          <Typography className={classes.cardSubHeaderCss}>
            {event.eventOrganizerName}
          </Typography>
        }
      >
        {/* <Link to={`/show-event/${event._id}`}>{event.eventTitle}</Link> */}
      </CardHeader>

      <CardContent>
        <Typography className={classes.cardDescriptionCss}>
          {event.eventAdditionalInfo}
        </Typography>
        <Link to={`/show-event/${event._id}`}>{event.eventTitle}</Link>
      </CardContent>
      <CardActions>
        <Container align="right">
          <IconButton className={classes.cardIconCss} aria-label="Visit page">
            <ArrowForward
              onClick={() => {
                window.open(`/show-event/${event._id}`, '_self');
              }}
            >
              {/* <Link to={`/show-event/${event._id}`}>{event.eventTitle}</Link> */}
            </ArrowForward>
          </IconButton>
        </Container>
      </CardActions>
    </Card>
  );
}
