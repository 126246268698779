import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import { Link, Button } from '@material-ui/core';

const styles = (theme) => ({
  card: {
    marginTop: '5px',
    padding: '30px',
    scrollPaddingBlockEnd: true,
    marginBottom: '20px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'red',
  },
});

export class Success extends Component {
  continue = (e) => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.nextStep();
  };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };
  // redirectHome = (e) => {
  //   e.preventDefault();
  //   this.props.prevStep();
  //   setTimeout(function() {
  //     window.location.replace('/');
  //   }, 8081);
  // };

  render() {
    const { classes } = this.props;
    return (
      <MuiThemeProvider>
        <>
          <Grid>
            <Card className={classes.card}>
              <h1>Thank You For Your Submission</h1>
              <p>You will get an email with further instructions.</p>
              <Button
                type="button"
                className={classes.button}
                color="primary"
                variant="contained"
                onClick={() => {
                  window.open('/', '_self');
                }}
              >
                Return to Event List
              </Button>
            </Card>
          </Grid>
        </>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Success);
