import React, { Component } from 'react';
import axios from 'axios';
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import { Grid, makeStyles, Card, TextField, MenuItem } from '@material-ui/core';
// import { Button, Upload } from 'antd';
import { List, ListItem, ListItemText, Input } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import AuthService from '../services/auth.service';
import authHeader from '../services/auth-header';

const useStyles = makeStyles((theme) => ({
  eventGridItem: {
    backgroundColor: 'grey',
  },
  eventBreakdownItem: {
    color: 'white',
  },
  eventGrid: {
    backgroundColor: 'grey',
    marginTop: 50,
  },
  card: {
    marginTop: '5px',
    padding: '30px',
    scrollPaddingBlockEnd: true,
    marginBottom: '20px',
    spacing: '10px',
  },
  button: {
    // paddingLeft: "10px",
    marginLeft: '10px',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'red',
  },
}));

const eventTypes = [
  {
    value: 'Music',
    label: 'Music',
  },
  {
    value: 'Health',
    label: 'Health',
  },
  {
    value: 'Sport',
    label: 'Sport',
  },
  {
    value: 'Religion',
    label: 'Religion',
  },
];
const eventCost = [
  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Payable',
    label: 'Payable',
  },
  {
    value: 'Surprise',
    label: 'Surprise',
  },
];

const currentUser = AuthService.getCurrentUser();

class ProfileUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: '',
      // email: '',
      // password: '',
      // images: '',
      first_name: '',
      last_name: '',
      phone_number: '',
      // profile_pic: '',
      location: '',
      DOB: '',
      city: '',
      state: '',
      zip_code: '',
      country: '',
    };
  }

  componentDidMount() {
    const _id = currentUser.id;
    // const eventId = window.location.href.split('/')[4];
    // console.log('Id from site ' + eventId);
    console.log('User Id from site ' + _id);
    // console.log("Print id: " + this.props.match.params.id);
    axios
      .get(
        'https://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/users/' +
          _id,
        { headers: authHeader() }
        // {
        //   headers: {
        //     'Content-Type': 'multipart/form-data',
        //     Authorization: `Bearer ${currentUser.accessToken}`,
        //   },
        // }
      )
      .then((res) => {
        // this.setState({...this.state, event: res.data})
        this.setState({
          username: res.data.username,
          // email: res.data.email,
          // password: res.data.password,
          // images: res.data.images,
          first_name: res.data.first_name,
          last_name: res.data.last_name,
          phone_number: res.data.phone_number,
          // profile_pic: res.data.profile_pic,
          location: res.data.location,
          DOB: res.data.DOB,
          userAddress: res.data.userAddress,
          biography: res.data.biography,
          city: res.data.city,
          state: res.data.state,
          zip_code: res.data.zip_code,
          country: res.data.country,
        });
      })
      .catch((err) => {
        console.log('Error from UpdateProfileInfo');
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ images: event.target.files[0] });
  };

  // On file upload (click the upload button)
  onFileUpload = async () => {
    // const home = () => {
    //   window.open('/', '_self');
    // };
    // Create an object of formData
    var formData = new FormData();
    // formData.append(data, this.props.values);
    formData.append('images', this.state.images, this.state.images.name);
    // // Details of the uploaded file
    console.log(this.state.images);
    // // return formData;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const userId = window.location.href.split('/')[4];
    const userDetailPage = () => {
      window.open('/show-user/' + userId, '_self');
    };

    const data = {
      username: this.state.username,
      // email: this.state.email,
      // password: this.state.password,
      // images: this.state.images,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      phone_number: this.state.phone_number,
      // profile_pic: this.state.profile_pic,
      location: this.state.location,
      DOB: this.state.DOB,
      userAddress: this.state.userAddress,
      biography: this.state.biography,
      city: this.state.city,
      state: this.state.state,
      zip_code: this.state.zip_code,
      country: this.state.country,
    };

    console.log('test edit user profile');
    // const saveTodb = () => {
    var formData = new FormData();

    // Update the formData object
    // formData.append('images', this.state.images, this.state.images.name);

    // Details of the uploaded file
    console.log(this.state.images);
    // var data = this.props.values;
    const data1 = this.props.values;
    console.log(data1);
    const data2 = this.props.state;
    console.log(data2);
    const _id = currentUser.id;
    // var formData = new FormData();
    formData = { data, userId: _id, images: this.state.images };
    // formData.append('images', this.state.images, this.state.images.name);

    console.log(this.state.images);
    console.log(formData);

    axios
      .put(
        'https://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/users/' +
          _id,
        { headers: authHeader() },
        formData
        // {
        //   headers: {
        //     'Content-Type': 'multipart/form-data',
        //     Authorization: `Bearer ${currentUser.accessToken}`,
        //   },
        // }
      )
      // axios
      //   .put(
      //     // 'http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/' + this.props.match.params.id,
      //     'http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/' + userId,
      //     data
      //   )
      .then((res) => {
        // this.props.history.push('/show-event/' + this.props.match.params.id);
        userDetailPage();
      })
      .catch((err) => {
        console.log('Error in UpdateUserInfo!');
      });
  };

  render() {
    const { classes, upload } = this.props;
    return (
      <Grid>
        <form noValidate onSubmit={this.onSubmit}>
          <Card className={classes.card}>
            <TextField
              placeholder="Enter Your Username"
              label="Username"
              value={this.state.username}
              onChange={this.onChange}
              name="username"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Email"
              label="Email"
              value={this.state.email}
              onChange={this.onChange}
              name="email"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Password"
              label="Password"
              value={this.state.password}
              onChange={this.onChange}
              name="password"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Upload Your Last Name"
              label="Last Name"
              value={this.state.last_name}
              onChange={this.onChange}
              margin="normal"
              name="last_name"
              fullWidth
            />
            <br />
            <label htmlFor="contained-button-file">
              <Input
                type="file"
                multiple
                label="User Profile Pic"
                name="images"
                onChange={this.onFileChange.bind(this)}
                defaultValue={this.images}
                margin="normal"
                fullWidth
              />
              <Button
                className={classes.button}
                color="primary"
                name="images"
                variant="contained"
                // onClick={this.onClick.bind(this)}
                onClick={this.onFileUpload.bind(this)}
              >
                Upload
              </Button>
            </label>
            <br />

            <TextField
              select
              placeholder="Enter Your First Name"
              label="First Name"
              value={this.state.first_name}
              onChange={this.onChange}
              name="first_name"
              margin="normal"
              fullWidth
            >
              {eventTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <div className="form-group">
              <input
                type="date"
                placeholder="Enter Your Date of Birth"
                label="Date of Birth"
                name="DOB"
                className="form-control"
                value={this.state.DOB}
                onChange={this.onChange}
              />
            </div>
            <br />
            <div className="form-group">
              <input
                type="time"
                placeholder="Enter Your Phone Number"
                label="Phone Number"
                name="phone_number"
                className="form-control"
                value={this.state.phone_number}
                onChange={this.onChange}
              />
            </div>
            <br />
            <TextField
              placeholder="Enter User Address"
              label="User Address"
              value={this.state.userAddress}
              onChange={this.onChange}
              name="userAddress"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter user biography"
              label="User Biography"
              value={this.state.biography}
              onChange={this.onChange}
              name="biography"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your City"
              label="City"
              value={this.state.city}
              onChange={this.onChange}
              name="city"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter User State"
              label="Event State"
              value={this.state.state}
              onChange={this.onChange}
              name="state"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter User Zip"
              label="User Zip"
              value={this.state.zip_code}
              name="zip_code"
              onChange={this.onChange}
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter User Country"
              label="User Country"
              value={this.state.country}
              onChange={this.onChange}
              name="country"
              margin="normal"
              fullWidth
            />
            <br />
          </Card>
          <br />
          <br />

          <button
            type="submit"
            className="btn btn-outline-info btn-lg btn-block"
          >
            Update Profile
          </button>
        </form>
      </Grid>
    );
  }
}

// export default UpdateEventInfo_;
export default withStyles(useStyles)(ProfileUpdate);
