import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import '../App.css';
import axios from 'axios';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, withStyles } from '@material-ui/core';
// import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import { red } from "@material-ui/core/colors";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
// import GridItem from './GridItem';

import waveImg from '../shared/static/pic2.jpg';
import AuthService from '../services/auth.service';
import authHeader from '../services/auth-header';
const useStyles = makeStyles({
  media: {
    marginTop: '60px',
    height: '100px',
    width: '100px',
    // resize: { width: 50, height: 50 },
  },
  main: {
    backgroundColor: 'white',
  },
});
const currentUser = AuthService.getCurrentUser();
class showEventDetails_ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
    };
  }

  componentDidMount() {
    // console.log("Print id: " + this.props.match.params.id);
    const eventId = window.location.href.split('/')[4];
    console.log('Id from site ' + eventId);
    axios
      .get(
        'https://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/' +
          eventId,
        { headers: authHeader() }
        // {
        //   headers: {
        //     'Content-Type': 'multipart/form-data',
        //     // Authorization: `Bearer ${currentUser.accessToken}`,
        //   },
        // }
      )
      .then((res) => {
        // console.log("Print-showeventDetails-API-response: " + res.data);
        this.setState({
          event: res.data,
        });
      })
      .catch((err) => {
        console.log('Error from ShowEventDetails');
      });
  }

  onDeleteClick(id) {
    // const eventId = window.location.href.split('/')[4];
    // const home = (e) => {
    //   e.preventDefault();
    //   // PROCESS FORM //
    //   this.props.nextStep();
    // };
    const home = () => {
      window.open('/', '_self');
    };
    // onClick={() => {
    //   window.open('/', '_self');
    // }}
    axios
      .delete(
        'https://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/' +
          id,
        { headers: authHeader() }
      )
      .then((res) => {
        // this.props.history.push('/');
        home();
      })
      .catch((err) => {
        console.log('Error form ShowEventDetails_deleteClick');
      });
  }

  render() {
    const { classes } = this.props;
    const event = this.state.event;
    console.log(event);
    let EventItem = (
      <Grid item lg={12} md={12} sm={12} xs={12} className={classes.main}>
        <CardMedia
          className={classes.media}
          component="img"
          image={event.images}
          title={event.eventTitle}
        />
        <CardContent style={{ justifyContent: 'center' }}>
          <div>
            <Typography gutterBottom variant="h5" component="h2">
              <Link to={`/show-event/${event._id}`}>{event.eventTitle}</Link>
            </Typography>
            <table className="table table-hover table-dark">
              {/* <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">First</th>
            <th scope="col">Last</th>
            <th scope="col">Handle</th>
          </tr>
        </thead> */}
              <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td variant="h6">Title</td>
                  <td>
                    <Typography gutterBottom variant="h5" component="h2">
                      {event.eventTitle}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td variant="h6">Organizer</td>
                  <td>
                    <Typography gutterBottom variant="h6" component="h2">
                      {event.eventOrganizerName}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td variant="h6">Date & Time</td>
                  <td>
                    <Typography gutterBottom variant="h6" component="h2">
                      {event.eventDate} {' @ '} {event.eventTime}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th scope="row">4</th>
                  <td variant="h6">Time</td>
                  <td>
                    <Typography gutterBottom variant="h6" component="h2">
                      {event.eventTime}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th scope="row">5</th>
                  <td variant="h6">Event's Cost Amount in dollar ($)</td>
                  <td>
                    <Typography gutterBottom variant="h6" component="h2">
                      {event.eventCostAmount}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th scope="row">6</th>
                  <td variant="h6">Location</td>
                  <td>
                    <Typography gutterBottom variant="h6" component="h2">
                      {event.eventLocation}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th scope="row">7</th>
                  <td variant="h6">City & Country</td>
                  <td>
                    <Typography gutterBottom variant="h6" component="h2">
                      {event.eventCity} {', '} {event.eventCountry}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <th scope="row">8</th>
                  <td variant="h6">Details</td>
                  <td>
                    <Typography gutterBottom variant="h6" component="h2">
                      {event.eventAdditionalInfo}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
            {/* </Button> */}
            {/* <Typography gutterBottom variant="h5" component="h2">
              <Link to={`/show-event/${event._id}`}>{event.eventTitle}</Link>
            </Typography>
            <Typography gutterBottom variant="h6" component="h2">
              By: {event.eventOrganizerName}
            </Typography>
            <Typography gutterBottom variant="h6" component="h2">
              Date: {event.eventDate}
            </Typography>
            <Typography gutterBottom variant="h6" component="h2">
              Time: {event.eventTime}
            </Typography>
            <Typography gutterBottom variant="h6" component="h2">
              Location {event.eventLocation}
            </Typography>
            <Typography gutterBottom variant="h6" component="h2">
              Location {event.eventAdditionalInfo}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              {event.eventAdditionalInfo}
            </Typography> */}
            {currentUser ? (
              <CardActions style={{ justifyContent: 'center' }}>
                <IconButton
                  aria-label="add to favorites"
                  style={{ color: 'whitesmoke' }}
                >
                  <FavoriteIcon />
                </IconButton>
                <IconButton aria-label="share" style={{ color: 'whitesmoke' }}>
                  <ShareIcon />
                </IconButton>
                <Button
                  size="small"
                  color="primary"
                  type="button"
                  className="btn btn-outline-danger btn-lg btn-block"
                  onClick={this.onDeleteClick.bind(this, event._id)}
                >
                  Delete Event
                </Button>
                {/* <Button size="small" color="secondary"> */}
                <Link
                  to={`/edit-event/${event._id}`}
                  className="btn btn-outline-info btn-lg btn-block"
                >
                  Edit Event
                </Link>
                {/* </Button> */}
              </CardActions>
            ) : (
              ''
            )}
          </div>
        </CardContent>
      </Grid>
      // <div>
      //   <table className="table table-hover table-dark">
      //     {/* <thead>
      //     <tr>
      //       <th scope="col">#</th>
      //       <th scope="col">First</th>
      //       <th scope="col">Last</th>
      //       <th scope="col">Handle</th>
      //     </tr>
      //   </thead> */}
      //     <tbody>
      //       <tr>
      //         <th scope="row">1</th>
      //         <td>Title</td>
      //         <td>{event.eventTitle}</td>
      //       </tr>
      //       <tr>
      //         <th scope="row">2</th>
      //         <td>Organizer</td>
      //         <td>{event.eventOrganizerName}</td>
      //       </tr>
      //       <tr>
      //         <th scope="row">3</th>
      //         <td>Date</td>
      //         <td>{event.eventDate}</td>
      //       </tr>
      //       <tr>
      //         <th scope="row">4</th>
      //         <td>Time</td>
      //         <td>{event.eventTime}</td>
      //       </tr>
      //       <tr>
      //         <th scope="row">5</th>
      //         <td>Event's Cost Amount</td>
      //         <td>{event.eventCostAmount}</td>
      //       </tr>
      //       <tr>
      //         <th scope="row">6</th>
      //         <td>Location</td>
      //         <td>{event.eventLocation}</td>
      //       </tr>
      //       <tr>
      //         <th scope="row">7</th>
      //         <td>Details</td>
      //         <td>{event.eventAdditionalInfo}</td>
      //       </tr>
      //     </tbody>
      //   </table>
      // </div>
    );

    return (
      <div className="ShowEventDetails">
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <br /> <br />
              <Link to="/" className="btn btn-outline-warning float-left">
                Show Event List
              </Link>
            </div>
            <br />
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">
                <Typography gutterBottom variant="h4" component="h1">
                  <Link to={`/show-event/${event._id}`}>
                    {event.eventTitle}
                  </Link>{' '}
                  Record
                </Typography>
              </h1>
              <p className="lead text-center">
                View Event's Info by {event.eventOrganizerName}
              </p>
              <hr /> <br />
            </div>
          </div>
          <Grid className="col-md-10 m-auto">{EventItem}</Grid>
        </div>
      </div>
    );
  }
}

// export default showEventDetails_;
export default withStyles(useStyles)(showEventDetails_);
