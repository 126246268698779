import React from 'react';
import {
  Container,
  Typography,
  Link,
  Box,
  makeStyles,
} from '@material-ui/core';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn,
} from 'mdb-react-ui-kit';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';

const useStyles = makeStyles((theme) => ({
  footer: {
    padding: theme.spacing(6),
    color: '#ECFF1B',
    backgroundColor: 'grey',
  },
  logo: {
    maxWidth: 140,
    marginBottom: 50,
  },
}));

function Copyright() {
  const classes = useStyles();
  return (
    <Typography>
      <Link href="https://www.africongo.com">
        <Box>
          <img
            src={require('../../../src/shared/static/AfricongoSecond.png')}
            alt="logo"
            className={classes.logo}
          />
        </Box>
      </Link>
      {'© '}
      {new Date().getFullYear() + ' Copyright '}
    </Typography>
  );
}

export default function App() {
  const classes = useStyles();
  return (
    <MDBFooter
      className="text-center p-4 m-4"
      color="white"
      style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' }}
    >
      <MDBContainer className="p-4">
        <section className="mb-4">
          <a className="btn btn-outline-light m-1" href="#!" role="button">
            <MDBIcon fab icon="facebook-f" />
          </a>

          <a className="btn btn-outline-light m-1" href="#!" role="button">
            <MDBIcon fab icon="twitter" />
          </a>

          <a className="btn btn-outline-light m-1" href="#!" role="button">
            <MDBIcon fab icon="google" />
          </a>

          <a
            className="btn btn-outline-light m-1"
            href="https://www.instagram.com/bilili.events/"
            role="button"
          >
            <MDBIcon fab icon="instagram" />
          </a>

          {/* <a className='btn btn-outline-light btn-floating m-1' href='#!' role='button'> */}
          <a className="btn btn-outline-light m-1" href="#!" role="button">
            <MDBIcon fab icon="linkedin-in" />
          </a>
          {/* 
          <a className="btn btn-outline-light m-1" href="#!" role="button">
            <MDBIcon fab icon="github" />
          </a> */}
        </section>

        {/* <section className="">
          <form action="">
            <div className="row d-flex justify-content-center">
              <div className="col-auto">
                <p className="pt-2">
                  <strong>Sign up for our newsletter</strong>
                </p>
              </div>

              <MDBCol md="5" start="12">
                <MDBInput
                  contrast
                  type="email"
                  label="Email address"
                  className="mb-4"
                />
              </MDBCol>

              <div className="col-auto">
                <MDBBtn outline color="light" type="submit" className="mb-4">
                  Subscribe
                </MDBBtn>
              </div>
            </div>
          </form>
        </section> */}

        <section className="mb-4">
          <p>
            Bilili events pona ba evenement nionso ya Congo na monde entier.
          </p>
        </section>

        <section className="">
          <MDBRow>
            <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
              <h5 className="text-uppercase">Use Africongo</h5>

              <ul className="list-unstyled mb-0">
                <li>
                  <a href="aboutus" className="text-white">
                    About us
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Organization
                  </a>
                </li>
                <li>
                  <a href="datamanagement" className="text-white">
                    Data Management
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Careers
                  </a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
              <h5 className="text-uppercase">Plan Events</h5>

              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#!" className="text-white">
                    Free Events
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Affordable Events
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Recommended Events
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Specials
                  </a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
              <h5 className="text-uppercase">Find Events</h5>

              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#!" className="text-white">
                    Local Events
                  </a>
                </li>
                {/* <li>
                  <a href="#!" className="text-white">
                    Regional Events
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    National Events
                  </a>
                </li> */}
                <li>
                  <a href="#!" className="text-white">
                    Continental Events
                  </a>
                </li>
              </ul>
            </MDBCol>

            <MDBCol lg="3" md="6" className="mb-4 mb-md-0">
              <h5 className="text-uppercase">Connect With Us</h5>

              <ul className="list-unstyled mb-0">
                <li>
                  <a href="#!" className="text-white">
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.instagram.com/bilili.events/"
                    className="text-white"
                  >
                    Instagram
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Twitter
                  </a>
                </li>
                <li>
                  <a href="#!" className="text-white">
                    Youtube
                  </a>
                </li>
              </ul>
            </MDBCol>
          </MDBRow>
        </section>
      </MDBContainer>

      <div className={classes.footer}>
        <Copyright />
      </div>
    </MDBFooter>
  );
}
