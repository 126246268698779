import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// import Card from "@material-ui/core/Card";
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
// import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
// import { red } from "@material-ui/core/colors";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
// import GridItem from './GridItem';

import waveImg from '../../../src/shared/static/pic2.jpg';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  media: {
    marginTop: '60px',
    height: '400px',
  },
  main: {
    backgroundColor: 'white',
  },
});

export default function CardMedia_(props) {
  const event = props.event;
  const classes = useStyles();

  return (
    <Grid item lg={12} md={12} sm={12} xs={12} className={classes.main}>
      <CardMedia className={classes.media} image={waveImg} title="Festival" />
      <CardContent>
        <CardActions>
          <IconButton aria-label="add to favorites">
            <FavoriteIcon />
          </IconButton>
          <IconButton aria-label="share">
            <ShareIcon />
          </IconButton>
          <Button size="small" color="primary">
            Primary
          </Button>
          <Button size="small" color="secondary">
            Secondary
          </Button>
        </CardActions>
        <Typography gutterBottom variant="h5" component="h2">
          <Link to={`/show-event/${event._id}`}>{event.eventTitle}</Link>
        </Typography>
        <Typography gutterBottom variant="h6" component="h2">
          By: {event.eventOrganizerName}
        </Typography>
        <Typography gutterBottom variant="h6" component="h2">
          Time and Location {event.eventOrganizerName}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {event.eventAdditionalInfo}
        </Typography>
      </CardContent>
    </Grid>
  );
}
