import React from 'react';
import {
  Container,
  Toolbar,
  Typography,
  Box,
  Link,
  makeStyles,
  AppBar,
  IconButton,
  MenuItem,
  Drawer,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';

const useStyles = makeStyles((theme) => ({
  siteTitle: {
    fontWeight: 'bold',
    letterSpacing: 1.5,
    color: 'blue',
  },
  siteMenuDraw: {
    color: '#fff',
    backgroundColor: '#fff',
  },
  toolbar: {
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      //alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
  },
  toolbarMini: {
    backgroundColor: '#fff',
  },
  menuBox: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  menuOption: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(7),
    },
    color: 'black',
  },
  menuIcon: {
    color: 'black',
  },
  logo: {
    maxWidth: 140,
  },
}));

export default function NavigationBar() {
  const [state, setState] = useState({
    toggleMenu: false,
    toggleMenuOpen: false,
  });

  const { toggleMenu, toggleMenuOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, toggleMenu: true }))
        : setState((prevState) => ({ ...prevState, toggleMenu: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  const classes = useStyles();

  const displayToggleMenu = () => {
    const handleToggleMenuOpen = () =>
      setState((prevState) => ({
        ...prevState,
        toggleMenuOpen: true,
      }));

    const handleToggleMenuClose = () =>
      setState((prevState) => ({
        ...prevState,
        toggleMenuOpen: false,
      }));

    return (
      <Toolbar className={classes.toolbarMini}>
        {/* <Box><img src="https://wwwen.uni.lu/var/storage/images/media/images/unilu2/1299853-1-fre-FR/unilu.jpg" alt="logo" className={classes.logo} /></Box> */}
        <Box>
          <img
            src={require('../../../src/shared/static/BililiLogo.png')}
            alt="logo"
            className={classes.logo}
          />
        </Box>
        <IconButton
          {...{
            onClick: handleToggleMenuOpen,
          }}
        >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        <Drawer
          {...{
            anchor: 'left',
            open: toggleMenuOpen,
            onClose: handleToggleMenuClose,
          }}
        >
          <div className={classes.siteMenuDraw}> {getToggleMenuOptions()}</div>
        </Drawer>
        <Typography component="h1" variant="h6" className={classes.siteTitle}>
          .
        </Typography>
      </Toolbar>
    );
  };

  const getToggleMenuOptions = () => {
    return (
      <Box className={classes.menuBox}>
        {['home', 'create an event', 'register', 'login'].map((menuOption) => (
          <MenuItem
            className={classes.menuOption}
            onClick={() => {
              window.open(menuOption, '_self');
            }}
          >
            {' '}
            {menuOption.toUpperCase()}{' '}
          </MenuItem>
        ))}
      </Box>
    );
  };

  const displayLargeMenu = () => {
    return (
      <Toolbar className={classes.toolbar}>
        {/* <Box><img src="https://wwwen.uni.lu/var/storage/images/media/images/unilu2/1299853-1-fre-FR/unilu.jpg" alt="logo" className={classes.logo} /></Box> */}
        <Box>
          <img
            src={require('../../../src/shared/static/BililiLogo.png')}
            alt="logo"
            className={classes.logo}
          />
        </Box>
        <Typography component="h1" variant="h6" className={classes.siteTitle}>
          .
        </Typography>

        <Box className={classes.menuBox}>
          {['home', 'create an event', 'register', 'login'].map(
            (menuOption) => (
              <Link
                component="button"
                variant="body1"
                className={classes.menuOption}
                onClick={() => {
                  window.open(menuOption, '_self');
                }}
              >
                {menuOption.toUpperCase()}
              </Link>
            )
          )}
        </Box>
      </Toolbar>
    );
  };

  return (
    <Container>
      <AppBar>{toggleMenu ? displayToggleMenu() : displayLargeMenu()}</AppBar>
    </Container>
  );
}
