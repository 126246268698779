import React from 'react';
import { CssBaseline, Container } from '@material-ui/core';
import NavigationBar from './layouts/NavigationBar';
import CardMedia_ from './layouts/CardMedia_';
import Footer from './layouts/Footer_';
import '../App.css';

const axios = require('axios');

function AppStart() {
  // Make a request for a user with a given ID
  axios.get('/api/user').then(function (response) {
    // handle success
    console.log(response);
  });

  return (
    <div className="App">
      <CssBaseline />
      <NavigationBar />
      <Container>
        <main>
          {/* <EventDescription />
          <CardContent />
          <CardActionsArea />
          <CardActions />
          <CardHeader /> */}
          <CardMedia_ />
        </main>
      </Container>

      <Footer />
    </div>
  );
}

export default AppStart;
