import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import FilesUploadComponent from '../../components/files-upload-component';
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
import { Grid, IconButton } from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { Button } from 'antd';

import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';

const styles = (theme) => ({
  card: {
    marginTop: '5px',
    padding: '30px',
    scrollPaddingBlockEnd: true,
    marginBottom: '20px',
    spacing: '10px',
  },
  button: {
    // paddingLeft: "10px",
    marginLeft: '10px',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'red',
  },
});

export class FormEventDetails extends Component {
  continue = (e) => {
    e.preventDefault();
    this.props.nextStep();
  };

  // state = {
  //   // Initially, no file is selected
  //   images: null,
  // };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ images: event.target.files[0] });
  };

  // On file upload (click the upload button)
  onFileUpload = async () => {
    // Create an object of formData
    const formData = new FormData();

    // Update the formData object
    formData.append('images', this.state.images, this.state.images.name);

    // Details of the uploaded file
    console.log(this.state.images);
    return formData;

    // Request made to the backend api
    // Send formData object
    // axios.post('api/uploadfile', formData);
    // await axios.post('http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/', formData, {
    //   headers: {
    //     'Content-Type': 'multipart/form-data',
    //     // Authorization: `Bearer ${userInfo.token}`,
    //   },
    // });
    // this.setState('image');
  };

  // upload = async (e) => {
  //   console.log('Here we are...........');
  //   // const file = e.target.files[0];
  //   const bodyFormData = new FormData();
  //   bodyFormData.append('images', e.target.files[0]);
  //   try {
  //     console.log('Here we are...........');
  //     // const { data } = await axios.post('/api/uploads', bodyFormData, {
  //     await axios.post('/', bodyFormData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         // Authorization: `Bearer ${userInfo.token}`,
  //       },
  //     });
  //     // setImage(data);
  //     // dispatch({ type: 'UPLOAD_SUCCESS' });
  //   } catch (error) {
  //     // toast.error(getError(error));
  //     // dispatch({ type: 'UPLOAD_FAIL' });
  //   }
  // };

  // back = (e) => {
  //   e.preventDefault();
  //   this.props.prevStep();
  // };

  render() {
    const { values, handleChange, classes } = this.props;
    return (
      <MuiThemeProvider>
        <form encType="multipart/form-data">
          <Grid>
            <Card className={classes.card}>
              <TextField
                placeholder="Enter Your Event Title"
                label="Event Title"
                name="eventTitle"
                onChange={handleChange('eventTitle')}
                defaultValue={values.eventTitle}
                margin="normal"
                fullWidth
              />
              <br />
              <TextField
                placeholder="Enter Your Event Organizer Name"
                label="Event Organizer Name"
                name="eventOrganizerName"
                onChange={handleChange('eventOrganizerName')}
                defaultValue={values.eventOrganizerName}
                margin="normal"
                fullWidth
              />
              {/* <br />
              <TextField
                placeholder="Enter Your Event Image"
                label="Event Image"
                onChange={handleChange('image')}
                defaultValue={values.image}
                margin="normal"
                fullWidth
              /> */}
              <br />
              <TextField
                placeholder="Enter Your Event Organizer Email"
                label="Event Organizer Email"
                name="organizerEmail"
                onChange={handleChange('organizerEmail')}
                defaultValue={values.organizerEmail}
                margin="normal"
                fullWidth
              />
              <br />
              {/* <IconButton
                color="primary"
                component="label"
                onChange={handleChange('image')}
                defaultValue={values.image}
              >
                <input type="file" accept="image/*" hidden />
                <AttachFileIcon fontSize="medium" />
              </IconButton> */}

              <TextField
                placeholder="Enter Your Event Organizer Phone Number"
                label="Event Organizer Phone Number"
                name="organizerPhone"
                onChange={handleChange('organizerPhone')}
                defaultValue={values.organizerPhone}
                margin="normal"
                fullWidth
              />
              <br />
              {/* <Upload onChange={upload} multiple={false} maxCount={1}>
                <Button
                  style={{ marginTop: 10 }}
                  // icon={<UploadOutlined />}
                  block
                >
                  Event's Flyer Image
                </Button>
              </Upload> */}

              <br />
              <div className="form-group">
                <input
                  type="file"
                  multiple
                  label="Event Flyer or Image"
                  name="images"
                  onChange={this.onFileChange.bind(this)}
                  defaultValue={values.images}
                  margin="normal"
                  fullWidth
                />
                <Button
                  className={classes.button}
                  color="primary"
                  name="images"
                  variant="contained"
                  onClick={this.onFileUpload.bind(this)}
                >
                  Upload
                </Button>

                {/* <button
                  className="btn btn-primary"
                  type="submit"
                  onClick={this.upload}
                >
                  Upload
                </button> */}
              </div>
              {/* <FilesUploadComponent /> */}
            </Card>
          </Grid>
          {/* <Button
              color="secondary"
              variant="contained"
              onClick={this.back}
            >Back</Button> */}

          <Button
            className={classes.button}
            color="primary"
            variant="contained"
            onClick={this.continue}
          >
            Continue
          </Button>
        </form>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(FormEventDetails);
