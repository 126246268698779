import * as api from '../../api';
import { openAlertMessage } from './alertActions';

export const authActions = {
  SET_USER_DETAILS: 'AUTH.SET_USER_DETAILS',
  SET_EVENT_DETAILS: 'AUTH.SET_EVENT_DETAILS',
};

export const getActions = (dispatch) => {
  return {
    login: (userDetails, navigate) => dispatch(login(userDetails, navigate)),
    createEvent: (eventDetails, navigate) =>
      dispatch(createEvent(eventDetails, navigate)),
    register: (userDetails, navigate) =>
      dispatch(register(userDetails, navigate)),
  };
};

const setUserDetails = (userDetails) => {
  return {
    type: authActions.SET_USER_DETAILS,
    userDetails,
  };
};

const setEventDetails = (eventDetails) => {
  return {
    type: authActions.SET_EVENT_DETAILS,
    eventDetails,
  };
};

const login = (userDetails, navigate) => {
  return async (dispatch) => {
    const response = await api.login(userDetails);
    // console.log(response);
    if (response.error) {
      dispatch(openAlertMessage(response?.exception?.response?.data));
    } else {
      const { userDetails } = response?.data;
      localStorage.setItem('user', JSON.stringify(userDetails));

      dispatch(setUserDetails(userDetails));
      navigate('/');
      // navigate('/dashboard');
    }
  };
};

const createEvent = (eventDetails, navigate) => {
  return async (dispatch) => {
    const response = await api.createEvent(eventDetails);
    // console.log(response);
    if (response.error) {
      dispatch(openAlertMessage(response?.exception?.response?.data));
    } else {
      const { eventDetails } = response?.data;
      localStorage.setItem('event', JSON.stringify(eventDetails));

      dispatch(setEventDetails(eventDetails));
      navigate('/dashboard');
    }
  };
};

const register = (userDetails, navigate) => {
  return async (dispatch) => {
    const response = await api.register(userDetails);
    // console.log(response);
    if (response.error) {
      dispatch(openAlertMessage(response?.exception?.response?.data));
    } else {
      const { userDetails } = response?.data;
      localStorage.setItem('user', JSON.stringify(userDetails));

      dispatch(setUserDetails(userDetails));
      navigate('/login');
      // navigate('/dashboard');
    }
  };
};
