import React, { Component } from 'react';
import '../App.css';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, withStyles } from '@material-ui/core';

const useStyles = makeStyles({
  media: {
    marginTop: '60px',
    height: '400px',
  },
  main: {
    backgroundColor: 'white',
  },
});

class showEventDetails_ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
    };
  }

  render() {
    return (
      <div className="ShowEventDetails">
        <div className="container">
          <div className="row">
            {/* <div className="col-md-10 m-auto">
              <br /> <br />
              <Link to="/" className="btn btn-outline-warning float-left">
                Show Event List
              </Link>
            </div> */}
            <br />
            <div className="col-md-8 m-auto">
              <h1 className="text-center">About Bilili Events</h1>
              <p className="lead text-center">
                La présente politique de confidentialité définit et vous informe
                de la manière dont BILILI EVENTS utilise et protège les
                informations que vous nous transmettez, le cas échéant, lorsque
                vous utilisez le présent site accessible à partir de l’URL
                suivante : www.XXXX (ci-après le « Site »). Veuillez noter que
                cette politique de confidentialité est susceptible d’être
                modifiée ou complétée à tout moment par, notamment en vue de se
                conformer à toute évolution législative, règlementaire,
                jurisprudentielle ou technologique. Dans un tel cas, la date de
                sa mise à jour sera clairement identifiée en tête de la présente
                politique. Ces modifications engagent l’Utilisateur dès leur
                mise en ligne. Il convient par conséquent que l’Utilisateur
                consulte régulièrement la présente politique de confidentialité
                et d’utilisation des cookies afin de prendre connaissance de ses
                éventuelles modifications.
              </p>
              <hr /> <br />
              <h4 className="text-center">DONNÉES PERSONNELLES</h4>
              <p className="lead text-center">
                D’une manière générale, il vous est possible de visiter le Site
                de BILILI EVENTS sans communiquer aucune information personnelle
                vous concernant. En toute hypothèse, vous êtes en aucune manière
                obligé de transmettre ces informations à BILILI EVENTS
                Néanmoins, en cas de refus, il se peut que vous ne puissiez pas
                bénéficier de certaines informations ou services que vous avez
                demandé. A ce titre en effet, BILILI EVENTS peut être amené dans
                certains cas à vous demander de renseigner vos nom, prénom,
                adresse email, numéro de téléphone, entreprise et fonction
                (ci-après vos « Informations Personnelles »). En fournissant ces
                informations, vous acceptez expressément qu’elles soient
                traitées par BILILI EVENTS, aux fins indiquées au point 2
                ci-dessous ainsi qu’aux fins rappelées à la fin de chaque
                formulaire. Conformément au Règlement Général sur la Protection
                des Données (General Data Protection Régulation) adopté par le
                Parlement européen le 14 avril 2016, et à la Loi Informatique et
                Libertés du 6 janvier 1978 modifiée, BILILI EVENTS vous informe
                des points suivants : 1. Identité du responsable du traitement
                2. Finalités du traitement ​ BILILI EVENTS est susceptible de
                traiter vos Informations Personnelles : (a) aux fins de vous
                fournir les informations ou les services que vous avez demandé
                (notamment : l’envoi de la Newsletter, offre commerciale, livres
                blancs ou encore l’évaluation de votre niveau de conformité via
                un quizz) ; et/ou (b) aux fins de recueillir des informations
                nous permettant d’améliorer notre Site, nos produits et services
                (notamment par le biais de cookies) ; et/ou (c) aux fins de
                pouvoir vous contacter à propos de différents évènements
                relatifs à BILILI EVENTS, incluant notamment la mise à jour des
                produits et le support client. 3. Destinataires Seul BILILI
                EVENTS est destinataire de vos Informations Personnelles.
                Celles-ci, que ce soit sous forme individuelle ou agrégée, ne
                sont jamais transmises à un tiers, nonobstant les sous-traitants
                auxquels BILILI EVENTS fait appel (vous trouverez de plus amples
                informations à leur sujet au point 7 ci-dessous). Ni BILILI
                EVENTS ni l’un quelconque de ses sous-traitants, ne procèdent à
                la commercialisation des données personnelles des visiteurs et
                Utilisateurs de son Site. 4. Durée de conservation Vos
                Informations Personnelles sont conservées par BILILI EVENTS
                uniquement pour le temps correspondant à la finalité de la
                collecte tel qu’indiqué en 2 ci-dessus qui ne saurait en tout
                état de cause excéder 24 mois. 5. Droits Informatique et
                Libertés Vous disposez des droits suivants concernant vos
                Informations Personnelles, que vous pouvez exercer en nous
                écrivant à l’adresse postale mentionnée au point 1 ou en
                remplissant ce formulaire.
              </p>
              <hr /> <br />
              <h4 className="text-center">
                Droit d’accès et de communication des données
              </h4>
              <p className="lead text-center">
                Vous avez la faculté d’accéder aux Informations Personnelles qui
                vous concernent. Cependant, en raison de l’obligation de
                sécurité et de confidentialité dans le traitement des données à
                caractère personnel qui incombe à BILILI EVENTS, Vous êtes
                informé que votre demande sera traitée sous réserve que vous
                rapportiez la preuve de votre identité, notamment par la
                production d’un scan de votre titre d’identité valide (en cas de
                demande par notre formulaire électronique dédié) ou d’une
                photocopie signée de votre titre d’identité valide (en cas de
                demande adressée par écrit). BILILI EVENTS vous informe qu’il
                sera en droit, le cas échéant, de s’opposer aux demandes
                manifestement abusives (de par leur nombre, leur caractère
                répétitif ou systématique). Pour vous aider dans votre démarche,
                notamment si vous désirez exercer votre droit d’accès par le
                biais d’une demande écrite à l’adresse postale mentionnée au
                point 1, vous trouverez en cliquant sur le lien suivant un
                modèle de courrier élaboré par la Commission Nationale de
                l’Informatique et des Libertés (la « CNIL »).
                https://www.cnil.fr/fr/modele/courrier/exercer-son-droit-dacces
              </p>
              <hr /> <br />
              <h4 className="text-center">
                Droit de rectification des données
              </h4>
              <p className="lead text-center">
                Au titre de ce droit, la législation vous habilite à demander la
                rectification, la mise à jour, le verrouillage ou encore
                l’effacement des données vous concernant qui peuvent s’avérer le
                cas échéant inexactes, erronées, incomplètes ou obsolètes.
                Également, vous pouvez définir des directives générales et
                particulières relatives au sort des données à caractère
                personnel après votre décès. Le cas échéant, les héritiers d’une
                personne décédée peuvent exiger de prendre en considération le
                décès de leur proche et/ou de procéder aux mises à jour
                nécessaires. ​ Pour vous aider dans votre démarche, notamment si
                vous désirez exercer, pour votre propre compte ou pour le compte
                de l’un de vos proches décédé, votre droit de rectification par
                le biais d’une demande écrite à l’adresse postale mentionnée au
                point 1, vous trouverez en cliquant sur le lien suivant un
                modèle de courrier élaboré par la CNIL.
                https://www.cnil.fr/fr/modele/courrier/rectifier-des-donnees-inexactes-obsoletes-ou-perimees
              </p>
              <hr /> <br />
              <h4 className="text-center">Droit d’opposition</h4>
              <p className="lead text-center">
                L’exercice de ce droit n’est possible que dans l’une des deux
                situations suivantes : Lorsque l’exercice de ce droit est fondé
                sur des motifs légitimes ; ou Lorsque l’exercice de ce droit
                vise à faire obstacle à ce que les données recueillies soient
                utilisées à des fins de prospection commerciale. Pour vous aider
                dans votre démarche, notamment si vous désirez exercer votre
                droit d’opposition par le biais d’une demande écrite adressée à
                l’adresse postale indiquée au point 1, vous trouverez en
                cliquant sur le lien suivant un modèle de courrier élaboré par
                la CNIL. 6. Délais de réponse BILILI EVENTS s’engage à répondre
                à votre demande d’accès, de rectification ou d’opposition ou
                toute autre demande complémentaire d’informations dans un délai
                raisonnable qui ne saurait dépasser 1 mois à compter de la
                réception de votre demande. 7. Prestataires habilités et
                transfert vers un pays tiers de l’Union Européenne BILILI EVENTS
                vous informe qu’il a recours à ses prestataires habilités pour
                faciliter le recueil et le traitement des données que vous nous
                avez communiqué. Ces prestataires peuvent être situés en dehors
                de l’Union Européenne et ont communication des données
                recueillies par le biais des divers formulaires présents sur le
                Site (à l’exception du formulaire vous permettant d’exercer vos
                droits Informatique et Libertés qui est proposé et exploité par
                BILILI EVENTS). BILILI EVENTS s’est préalablement assuré de la
                mise en œuvre par ses prestataires de garanties adéquates et du
                respect de conditions strictes en matière de confidentialité,
                d’usage et de protection des données. Tout particulièrement, la
                vigilance s’est portée sur l’existence d’un fondement légal pour
                effectuer un quelconque transfert de données vers un pays tiers.
                A ce titre, l’un de nos prestataires est soumis à des règles
                internes d’entreprise (ou « Binding Corporate Rules ») qui ont
                été approuvées par la CNIL en 2016 quand les autres obéissent
                non seulement à des Clauses Contractuelles Types mais également
                au Privacy Shield. 8. Plainte auprès de l’autorité compétente Si
                vous considérez que BILILI EVENTS ne respecte pas ses
                obligations au regard de vos Informations Personnelles, vous
                pouvez adresser une plainte ou une demande auprès de l’autorité
                compétente. En France, l’autorité compétente est la CNIL à
                laquelle vous pouvez adresser une demande par voie électronique
                en cliquant sur le lien suivant :
                https://www.cnil.fr/fr/plaintes/internet.
              </p>
              <hr /> <br />
              <h4 className="text-center">
                II. POLITIQUE RELATIVE AUX COOKIES
              </h4>
              <p className="lead">
                Lors de votre première connexion sur le site web de BILILI
                EVENTS, vous êtes avertis par un bandeau en bas de votre écran
                que des informations relatives à votre navigation sont
                susceptibles d’être enregistrées dans des fichiers dénommés «
                cookies ». Notre politique d’utilisation des cookies vous permet
                de mieux comprendre les dispositions que nous mettons en œuvre
                en matière de navigation sur notre site web. Elle vous informe
                notamment sur l’ensemble des cookies présents sur notre site
                web, leur finalité (partie I.) et vous donne la marche à suivre
                pour les paramétrer (partie II.) 1. Informations générales sur
                les cookies présents sur le site de BILILI EVENTS BILILI EVENTS,
                en tant qu’éditeur du présent site web, pourra procéder à
                l’implantation d’un cookie sur le disque dur de votre terminal
                (ordinateur, tablette, mobile etc.) afin de vous garantir une
                navigation fluide et optimale sur notre site Internet. Les «
                cookies » (ou témoins de connexion) sont des petits fichiers
                texte de taille limitée qui nous permettent de reconnaître votre
                ordinateur, votre tablette ou votre mobile aux fins de
                personnaliser les services que nous vous proposons. Les
                informations recueillies par le biais des cookies ne permettent
                en aucune manière de vous identifier nominativement. Elles sont
                utilisées exclusivement pour nos besoins propres afin
                d’améliorer l’interactivité et la performance de notre site web
                et de vous adresser des contenus adaptés à vos centres
                d’intérêts. Aucune de ces informations ne fait l’objet d’une
                communication auprès de tiers sauf lorsque BILILI EVENTS a
                obtenu au préalable votre consentement ou bien lorsque la
                divulgation de ces informations est requise par la loi, sur
                ordre d’un tribunal ou toute autorité administrative ou
                judiciaire habilitée à en connaître. Pour mieux vous éclairer
                sur les informations que les cookies identifient, vous trouverez
                ci-dessous un tableau listant les différents types de cookies
                susceptibles d’être utilisés sur le site web de BILILI EVENTS,
                leur nom, leur finalité ainsi que leur durée de conservation. 2.
                Configuration de vos préférences sur les cookies Vous pouvez
                accepter ou refuser le dépôt de cookies à tout moment Lors de
                votre première connexion sur le site web de BILILI EVENTS, une
                bannière présentant brièvement des informations relatives au
                dépôt de cookies et de technologies similaires apparaît en bas
                de votre écran. Cette bannière vous avertit qu’en poursuivant
                votre navigation sur le site web de BILILI EVENTS (en chargeant
                une nouvelle page ou en cliquant sur divers éléments du site par
                exemple), vous acceptez le dépôt de cookies sur votre terminal.
                Vous êtes également réputé avoir donné votre accord au dépôt de
                cookies en cliquant sur l’icône « X » à droite de la bannière
                figurant en bas de votre écran. Selon le type de cookie en
                cause, le recueil de votre consentement au dépôt et à la lecture
                de cookies sur votre terminal peut être impératif. a. Les
                cookies exemptés de consentement Conformément aux
                recommandations de la Commission Nationale de l’Informatique et
                des Libertés (CNIL), certains cookies sont dispensés du recueil
                préalable de votre consentement dans la mesure où ils sont
                strictement nécessaires au fonctionnement du site internet ou
                ont pour finalité exclusive de permettre ou faciliter la
                communication par voie électronique. Il s’agit notamment des
                cookies d’identifiant de session, d’authentification, de session
                d’équilibrage de charge ainsi que des cookies de
                personnalisation de votre interface. Ces cookies sont
                intégralement soumis à la présente politique dans la mesure où
                ils sont émis et gérés par BILILI EVENTS. b. Les cookies
                nécessitant le recueil préalable de votre consentement Cette
                exigence concerne les cookies émis par des tiers et qui sont
                qualifiés de « persistants » dans la mesure où ils demeurent
                dans votre terminal jusqu’à leur effacement ou leur date
                d’expiration. De tels cookies étant émis par des tiers, leur
                utilisation et leur dépôt sont soumis à leurs propres politiques
                de confidentialité dont vous trouverez un lien ci-dessous. Cette
                famille de cookie regroupe les cookies de mesure d’audience
                (notamment Google Analytics), les cookies publicitaires
                (auxquels BILILI EVENTS n’a pas recours) ainsi que les cookies
                de partage de réseaux sociaux (notamment de Facebook, YouTube,
                Twitter et LinkedIn). Les cookies de mesure d’audience
                établissent des statistiques concernant la fréquentation et
                l’utilisation de divers éléments du site web (comme les
                contenus/pages que vous avez visité). Ces données participent à
                l’amélioration de l’ergonomie du site web de BILILI EVENTS Un
                outil de mesure d’audience est utilisé sur le présent site
                internet : o Google Analytics dont la politique de
                confidentialité est disponible (uniquement en anglais) à partir
                du lien suivant : https://policies.google.com/privacy/update Les
                cookies de partage des réseaux sociaux sont émis et gérés par
                l’éditeur du réseau social concerné. Sous réserve de votre
                consentement, ces cookies vous permettent de partager facilement
                une partie du contenu publié sur le site de BILILI EVENTS
                notamment par l’intermédiaire d’un « bouton » applicatif de
                partage selon le réseau social concerné. Quatre types de cookies
                de partage des réseaux sociaux sont présents sur le site de
                BILILI EVENTS: o Facebook, dont vous pouvez consulter la
                politique en matière de cookies en cliquant sur le lien suivant
                : https://fr-fr.facebook.com/policies/cookies/ o LinkedIn, dont
                vous trouverez la politique relative aux cookies en cliquant sur
                le lien suivant :
                https://www.linkedin.com/legal/cookie-policy?_l=fr_FR o Twitter,
                dont vous trouverez les options dédiées au contrôle ou à la
                restriction de l’utilisation des cookies ainsi que la politique
                d’utilisation des cookies :
                https://support.twitter.com/articles/20170518# o YouTube, dont
                vous trouverez l’aide nécessaire pour supprimer les cookies à
                partir du navigateur Google Chrome en cliquant sur le lien
                suivant : https://support.google.com/youtube/answer/32050?hl=fr
                mais également la politique complète en matière de cookies à
                partir du lien suivant :
                https://www.google.fr/intl/fr/policies/technologies/cookies/ c.
                Vous disposez de divers outils de paramétrage des cookies La
                plupart des navigateurs Internet sont configurés par défaut de
                façon à ce que le dépôt de cookies soit autorisé. Votre
                navigateur vous offre l’opportunité de modifier ces paramètres
                standards de manière à ce que l’ensemble des cookies soit rejeté
                systématiquement ou bien à ce qu’une partie seulement des
                cookies soit acceptée ou refusée en fonction de leur émetteur.
                ATTENTION : Nous attirons votre attention sur le fait que le
                refus du dépôt de cookies sur votre terminal est néanmoins
                susceptible d’altérer votre expérience d’utilisateur ainsi que
                votre accès à certains services ou fonctionnalités du présent
                site web. Le cas échéant, BILILI EVENTS décline toute
                responsabilité concernant les conséquences liées à la
                dégradation de vos conditions de navigation qui interviennent en
                raison de votre choix de refuser, supprimer ou bloquer les
                cookies nécessaires au fonctionnement du site. Ces conséquences
                ne sauraient constituer un dommage et vous ne pourrez prétendre
                à aucune indemnité de ce fait. Votre navigateur vous permet
                également de supprimer les cookies existants sur votre terminal
                ou encore de vous signaler lorsque de nouveaux cookies sont
                susceptibles d’être déposés sur votre terminal. Ces paramétrages
                n’ont pas d’incidence sur votre navigation mais vous font perdre
                tout le bénéfice apporté par le cookie. Veuillez ci-dessous
                prendre connaissance des multiples outils mis à votre
                disposition afin que vous puissiez paramétrer les cookies
                déposés sur votre terminal. d. Le paramétrage de votre
                navigateur Internet Chaque navigateur Internet propose ses
                propres paramètres de gestion des cookies. Pour savoir de quelle
                manière modifier vos préférences en matière de cookies, vous
                trouverez ci-dessous les liens vers l’aide nécessaire pour
                accéder au menu de votre navigateur prévu à cet effet : Chrome :
                https://support.google.com/chrome/answer/95647?hl=fr Firefox :
                https://support.mozilla.org/fr/kb/activer-desactiver-cookies
                Internet Explorer :
                https://support.microsoft.com/fr-fr/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11
                Opera : https://help.opera.com/Windows/10.20/fr/cookies.html
                Safari :
                https://support.apple.com/kb/PH21411?viewlocale=fr_FR&locale=fr_FR
                Pour de plus amples informations concernant les outils de
                maîtrise des cookies, vous pouvez consulter le site internet de
                la CNIL :
                https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser. e.
                Le paramétrage de l’outil tarte au citron La désactivation des
                cookies peut également se faire directement via l’outil tarte au
                citron mis en place sur le site BILILI EVENTS. Interactif, il
                permet de gérer vos cookies via un bandeau disponible en bas à
                droite de la page visionnée (bouton « Gestion des services ») et
                liste l’ensemble des cookies actifs. Pour désactiver un cookie,
                il suffit de cliquer sur la croix proche du service proposé. Si
                l’ensemble des cookies sont désactivés, le bandeau dédié à
                recueillir le consentement de l’utilisateur réapparaitra lors du
                rafraichissement de la page. ​
              </p>
              <hr /> <br />
              <h4 className="text-center">About Bilili Events</h4>
              <p className="lead text-center">View Event's Info</p>
              <hr /> <br />
              {/* <Grid>{EventItem}</Grid> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(showEventDetails_);
