import { Grid, Typography, makeStyles } from '@material-ui/core';
import Image from '../../../src/shared/static/pic5.jpg';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    height: 1000,
    backgroundImage: `url(${Image})`,
    color: theme.palette.warning.dark,
    backgroundSize: 'cover',
    //color: theme.palette.common.white,
    //backgroundSize: 'cover'
  },
  mainItem: {
    padding: theme.spacing(6),
    textAlign: 'left',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    color: 'yellow',
  },
}));

export default function MainGridItem() {
  const classes = useStyles();

  return (
    <Grid container className={classes.mainContainer}>
      <Grid item className={classes.mainItem} md={6}>
        <Typography component="h1" variant="h4">
          Welcome to Bilili Events
        </Typography>

        <Typography variant="body1" paragraph>
          The perfect place for your perfect events among the world best events!
        </Typography>
      </Grid>
    </Grid>
  );
}
