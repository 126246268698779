import React from 'react';
import {
  Container,
  Toolbar,
  Typography,
  Box,
  Link,
  makeStyles,
  AppBar,
  IconButton,
  MenuItem,
  Drawer,
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import AuthService from '../../services/auth.service';

const useStyles = makeStyles((theme) => ({
  siteTitle: {
    fontWeight: 'bold',
    letterSpacing: 1.5,
    color: 'blue',
  },
  siteMenuDraw: {
    color: '#fff',
    backgroundColor: '#fff',
  },
  toolbar: {
    display: 'flex',
    backgroundColor: '#fff',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      //alignItems: 'flex-end',
      justifyContent: 'space-between',
    },
  },
  toolbarMini: {
    backgroundColor: '#fff',
  },
  menuBox: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  menuOption: {
    padding: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(7),
    },
    color: 'black',
  },
  menuIcon: {
    color: 'black',
  },
  logo: {
    maxWidth: 140,
  },
}));

export default function NavigationBar() {
  const [state, setState] = useState({
    toggleMenu: false,
    toggleMenuOpen: false,
  });

  const { toggleMenu, toggleMenuOpen } = state;
  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {}, []);

  const logOut = () => {
    AuthService.logout();
  };

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 960
        ? setState((prevState) => ({ ...prevState, toggleMenu: true }))
        : setState((prevState) => ({ ...prevState, toggleMenu: false }));
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());

    const user = AuthService.getCurrentUser();

    if (user) {
      setCurrentUser(user);
      setShowModeratorBoard(user.roles.includes('ROLE_MODERATOR'));
      setShowAdminBoard(user.roles.includes('ROLE_ADMIN'));
    }
  }, []);

  const classes = useStyles();

  const displayToggleMenu = () => {
    const handleToggleMenuOpen = () =>
      setState((prevState) => ({
        ...prevState,
        toggleMenuOpen: true,
      }));

    const handleToggleMenuClose = () =>
      setState((prevState) => ({
        ...prevState,
        toggleMenuOpen: false,
      }));

    return (
      <Toolbar className={classes.toolbarMini}>
        {/* <Box><img src="https://wwwen.uni.lu/var/storage/images/media/images/unilu2/1299853-1-fre-FR/unilu.jpg" alt="logo" className={classes.logo} /></Box> */}
        <Box>
          <img
            src={require('../../../src/shared/static/BililiLogo.png')}
            alt="logo"
            className={classes.logo}
          />
        </Box>
        <IconButton
          {...{
            onClick: handleToggleMenuOpen,
          }}
        >
          <MenuIcon className={classes.menuIcon} />
        </IconButton>
        <Drawer
          {...{
            anchor: 'left',
            open: toggleMenuOpen,
            onClose: handleToggleMenuClose,
          }}
        >
          <div className={classes.siteMenuDraw}> {getToggleMenuOptions()}</div>
        </Drawer>
        <Typography component="h1" variant="h6" className={classes.siteTitle}>
          .
        </Typography>
      </Toolbar>
    );
  };

  const getToggleMenuOptions = () => {
    return (
      <Box className={classes.menuBox}>
        <nav className=" navbar-dark bg-dark">
          <MenuItem
            onClick={() => {
              window.open('/', '_self');
            }}
          >
            Bilili
          </MenuItem>
          <div className="navbar-nav">
            <li className="nav-item">
              <MenuItem
                onClick={() => {
                  window.open('/', '_self');
                }}
              >
                Home
              </MenuItem>
            </li>

            {showModeratorBoard && (
              <li className="nav-item">
                <MenuItem to={'/mod'}>Moderator Board</MenuItem>
              </li>
            )}

            {showAdminBoard && (
              <li className="nav-item">
                <MenuItem
                  onClick={() => {
                    window.open('/admin', '_self');
                  }}
                >
                  Admin Board
                </MenuItem>
              </li>
            )}

            {currentUser && (
              <li className="nav-item">
                <MenuItem
                  onClick={() => {
                    window.open('/user', '_self');
                  }}
                >
                  User
                </MenuItem>
              </li>
            )}
          </div>

          {currentUser ? (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <MenuItem
                  onClick={() => {
                    window.open('/profile', '_self');
                  }}
                >
                  {currentUser.username}
                </MenuItem>
              </li>
              <li className="nav-item">
                <MenuItem>
                  <a href="/login" onClick={logOut}>
                    LogOut
                  </a>
                </MenuItem>
              </li>
            </div>
          ) : (
            <div className="navbar-nav ml-auto">
              <li className="nav-item">
                <MenuItem
                  onClick={() => {
                    window.open('/login', '_self');
                  }}
                >
                  Login
                </MenuItem>
              </li>

              <li className="nav-item">
                <MenuItem
                  onClick={() => {
                    window.open('/register', '_self');
                  }}
                >
                  Sign Up
                </MenuItem>
              </li>
            </div>
          )}
        </nav>
      </Box>
    );
  };

  const displayLargeMenu = () => {
    return (
      <Toolbar className={classes.toolbar}>
        {/* <Box><img src="https://wwwen.uni.lu/var/storage/images/media/images/unilu2/1299853-1-fre-FR/unilu.jpg" alt="logo" className={classes.logo} /></Box> */}
        <Box>
          <img
            src={require('../../../src/shared/static/BililiLogo.png')}
            alt="logo"
            className={classes.logo}
          />
        </Box>
        <Typography component="h1" variant="h6" className={classes.siteTitle}>
          .
        </Typography>

        <Box className={classes.menuBox}>
          <nav className="navbar navbar-expand navbar-dark bg-dark">
            <MenuItem
              onClick={() => {
                window.open('/', '_self');
              }}
            >
              Bilili
            </MenuItem>
            <div className="navbar-nav mr-auto">
              <li className="nav-item">
                <MenuItem
                  onClick={() => {
                    window.open('/', '_self');
                  }}
                >
                  Home
                </MenuItem>
              </li>

              {showModeratorBoard && (
                <li className="nav-item">
                  <MenuItem
                    onClick={() => {
                      window.open('/mod', '_self');
                    }}
                  >
                    Moderator Board
                  </MenuItem>
                </li>
              )}

              {showAdminBoard && (
                <li className="nav-item">
                  <MenuItem
                    onClick={() => {
                      window.open('/admin', '_self');
                    }}
                  >
                    Admin Board
                  </MenuItem>
                </li>
              )}

              {currentUser && (
                <li className="nav-item">
                  <MenuItem
                    onClick={() => {
                      window.open('/user', '_self');
                    }}
                  >
                    User
                  </MenuItem>
                </li>
              )}
            </div>

            {currentUser ? (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <MenuItem
                    onClick={() => {
                      window.open('/profile', '_self');
                    }}
                  >
                    {currentUser.username}
                  </MenuItem>
                </li>
                <li className="nav-item">
                  <MenuItem>
                    <a href="/login" onClick={logOut}>
                      LogOut
                    </a>
                  </MenuItem>
                </li>
              </div>
            ) : (
              <div className="navbar-nav ml-auto">
                <li className="nav-item">
                  <MenuItem
                    onClick={() => {
                      window.open('/login', '_self');
                    }}
                  >
                    Login
                  </MenuItem>
                </li>

                <li className="nav-item">
                  <MenuItem
                    onClick={() => {
                      window.open('/register', '_self');
                    }}
                  >
                    Sign Up
                  </MenuItem>
                </li>
              </div>
            )}
          </nav>
        </Box>
      </Toolbar>
    );
  };

  <nav className="navbar navbar-expand navbar-dark bg-dark"></nav>;

  return (
    <Container>
      <AppBar>{toggleMenu ? displayToggleMenu() : displayLargeMenu()}</AppBar>
    </Container>
  );
}
