import React, { Component, useState, useEffect } from 'react';
import FormEventDetails from './FormEventDetails';
import FormEventCostDetails from './FormEventCostDetails';
import FormPersonalDetails from './FormPersonalDetails';
import FilesUploadComponent from '../../components/files-upload-component';
import Confirm from './Confirm';
import Success from './Success';
// import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Card from '@material-ui/core/Card';

export class EventForm extends Component {
  state = {
    step: 1,
    eventTitle: '',
    eventOrganizerName: '',
    images: null,
    eventLocation: '',
    eventAddress: '',
    eventCity: '',
    eventState: '',
    eventZip: '',
    eventCountry: '',
    eventDate: '',
    eventTime: '',
    eventType: '',
    // createdDate: '',
    // publishedDate: '',
    eventCost: '',
    eventCostAmount: '',
    organizerEmail: '',
    organizerPhone: '',
    eventAdditionalInfo: '',
    eventMain: '',
    eventSponsored: '',
    eventSponsoredBy: '',
    // firstName: '',
    // lastName: '',
    // email: '',
    // occupation: '',
    // city: '',
    // bio: ''
  };

  // state = {
  //   // Initially, no file is selected
  //   images: null,
  // };

  // // On file select (from the pop up)
  // onFileChange = (event) => {
  //   // Update the state
  //   this.setState({ images: event.target.files[0] });
  // };

  // // On file upload (click the upload button)
  // onFileUpload = async () => {
  //   // Create an object of formData
  //   const formData = new FormData();

  //   // Update the formData object
  //   formData.append('images', this.state.images, this.state.images.name);

  //   // Details of the uploaded file
  //   console.log(this.state.images);

  //   // Request made to the backend api
  //   // Send formData object
  //   // axios.post('api/uploadfile', formData);
  //   await axios.post('http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/', formData, {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //       // Authorization: `Bearer ${userInfo.token}`,
  //     },
  //   });
  //   // this.setState('image');
  // };

  // Proceed to next step
  nextStep = () => {
    const { step } = this.state;
    this.setState({
      step: step + 1,
    });
  };

  // Go back to prev step
  prevStep = () => {
    const { step } = this.state;
    this.setState({
      step: step - 1,
    });
  };

  // Handle fields change
  handleChange = (input) => (e) => {
    this.setState({ [input]: e.target.value });
  };

  upload = async (e) => {
    const file = e.target.files[0];
    const bodyFormData = new FormData();
    bodyFormData.append('images', file);
    // var data = new FormData();
    // data.append('image', e.target.files[0]);
    // data.append('username', 'Saurabh'); //if you have other fields

    // axios.post('/api/upload/uploadImage', data)
    try {
      console.log('Here we are...........');
      // const { data } = await axios.post('/api/uploads', bodyFormData, {
      await axios.post('/', bodyFormData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          // Authorization: `Bearer ${userInfo.token}`,
        },
      });
      // setImage(data);
      // dispatch({ type: 'UPLOAD_SUCCESS' });
    } catch (error) {
      // toast.error(getError(error));
      // dispatch({ type: 'UPLOAD_FAIL' });
    }
    // axios
    //   .post('/', data)
    //   //  headers: {
    //   //   //your headers
    //   //  })
    //   .then((response) => {
    //     alert(JSON.stringify(response));
    //   })
    //   .catch(function (error) {
    //     console.log(error);
    //   });
  };

  // uploadFileHandler = async (e) => {
  //   const file = e.target.files[0];
  //   const bodyFormData = new FormData();
  //   bodyFormData.append('image', file);
  //   // dispatch({ type: 'UPLOAD_REQUEST' });
  //   try {
  //     const { data } = await axios.post('/api/uploads', bodyFormData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         Authorization: `Bearer ${userInfo.token}`,
  //       },
  //     });
  //     setImage(data);
  //     dispatch({ type: 'UPLOAD_SUCCESS' });
  //   } catch (error) {
  //     // toast.error(getError(error));
  //     // dispatch({ type: 'UPLOAD_FAIL' });
  //   }
  // };

  render() {
    const { step } = this.state;
    const {
      eventTitle,
      eventOrganizerName,
      images,
      eventLocation,
      eventAddress,
      eventCity,
      eventState,
      eventZip,
      eventCountry,
      eventDate,
      eventTime,
      eventType,
      // createdDate,
      // publishedDate,
      eventCost,
      eventCostAmount,
      organizerEmail,
      organizerPhone,
      eventAdditionalInfo,
      eventMain,
      eventSponsored,
      eventSponsoredBy,
    } = this.state;
    const values = {
      eventTitle,
      eventOrganizerName,
      images,
      eventLocation,
      eventAddress,
      eventCity,
      eventState,
      eventZip,
      eventCountry,
      eventDate,
      eventTime,
      eventType,
      // createdDate,
      // publishedDate,
      eventCost,
      eventCostAmount,
      organizerEmail,
      organizerPhone,
      eventAdditionalInfo,
      eventMain,
      eventSponsored,
      eventSponsoredBy,
    };

    switch (step) {
      case 1:
        return (
          <FormEventDetails
            nextStep={this.nextStep}
            handleChange={this.handleChange}
            // onFileChange={this.onFileChange}
            // onFileUpload={this.onFileUpload}
            values={values}
          />
        );
      case 2:
        return (
          <FormEventCostDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            // onFileChange={this.onFileChange}
            // onFileUpload={this.onFileUpload}
            values={values}
          />
        );
      case 3:
        return (
          <FormPersonalDetails
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            handleChange={this.handleChange}
            // onFileChange={this.onFileChange}
            // onFileUpload={this.onFileUpload}
            values={values}
          />
        );
      case 4:
        return (
          <Confirm
            nextStep={this.nextStep}
            prevStep={this.prevStep}
            // onFileChange={this.onFileChange}
            // onFileUpload={this.onFileUpload}
            values={values}
          />
        );
      case 5:
        return <Success />;
      default:
        console.log('This is a multi-step form built with React.');
    }
  }
}
export default EventForm;
