import React, { Component } from 'react';
import axios from 'axios';
import '../App.css';
import { withStyles } from '@material-ui/core/styles';
import { Grid, makeStyles, Card, TextField, MenuItem } from '@material-ui/core';
// import { Button, Upload } from 'antd';
import { List, ListItem, ListItemText, Input } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import AuthService from '../services/auth.service';
import authHeader from '../services/auth-header';

const useStyles = makeStyles((theme) => ({
  eventGridItem: {
    backgroundColor: 'grey',
  },
  eventBreakdownItem: {
    color: 'white',
  },
  eventGrid: {
    backgroundColor: 'grey',
    marginTop: 50,
  },
  card: {
    marginTop: '5px',
    padding: '30px',
    scrollPaddingBlockEnd: true,
    marginBottom: '20px',
    spacing: '10px',
  },
  button: {
    // paddingLeft: "10px",
    marginLeft: '10px',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'red',
  },
}));

const eventTypes = [
  {
    value: 'Music',
    label: 'Music',
  },
  {
    value: 'Health',
    label: 'Health',
  },
  {
    value: 'Sport',
    label: 'Sport',
  },
  {
    value: 'Religion',
    label: 'Religion',
  },
];
const eventCost = [
  {
    value: 'Free',
    label: 'Free',
  },
  {
    value: 'Payable',
    label: 'Payable',
  },
  {
    value: 'Surprise',
    label: 'Surprise',
  },
];

const currentUser = AuthService.getCurrentUser();

class UpdateEventInfo_ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eventTitle: '',
      eventOrganizerName: '',
      // images: '',
      eventLocation: '',
      eventAddress: '',
      eventCity: '',
      eventState: '',
      eventZip: '',
      eventCountry: '',
      eventDate: '',
      eventTime: '',
      eventType: '',
      createdDate: '',
      publishedDate: '',
      eventCost: '',
      eventCostAmount: '',
      organizerEmail: '',
      organizerPhone: '',
      eventAdditionalInfo: '',
      eventMain: '',
      eventSponsored: '',
      eventSponsoredBy: '',
    };
  }

  componentDidMount() {
    const eventId = window.location.href.split('/')[4];
    console.log('Id from site ' + eventId);
    // console.log("Print id: " + this.props.match.params.id);

    axios
      .get(
        'https://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/' +
          eventId,
        { headers: authHeader() }
        // {
        //   headers: {
        //     'Content-Type': 'multipart/form-data',
        //     Authorization: `Bearer ${currentUser.accessToken}`,
        //   },
        // }
      )
      .then((res) => {
        // this.setState({...this.state, event: res.data})
        this.setState({
          eventTitle: res.data.eventTitle,
          eventOrganizerName: res.data.eventOrganizerName,
          // images: res.data.images,
          eventLocation: res.data.eventLocation,
          eventCity: res.data.eventCity,
          eventState: res.data.eventState,
          eventZip: res.data.eventZip,
          eventCountry: res.data.eventCountry,
          eventDate: res.data.eventDate,
          eventTime: res.data.eventTime,
          eventType: res.data.eventType,
          createdDate: res.data.createdDate,
          publishedDate: res.data.publishedDate,
          eventCost: res.data.eventCost,
          eventCostAmount: res.data.eventCostAmount,
          organizerEmail: res.data.organizerEmail,
          organizerPhone: res.data.organizerPhone,
          eventAdditionalInfo: res.data.eventAdditionalInfo,
          eventMain: res.data.eventMain,
          eventSponsored: res.data.eventSponsored,
          eventSponsoredBy: res.data.eventSponsoredBy,
        });
      })
      .catch((err) => {
        console.log('Error from UpdateEventInfo');
      });
  }

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  // upload = async (e) => {
  //   const file = e.target.files[0];
  //   const bodyFormData = new FormData();
  //   bodyFormData.append('images', file);
  //   try {
  //     // const { data } = await axios.post('/api/uploads', bodyFormData, {
  //     await axios.post('/', bodyFormData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //         // Authorization: `Bearer ${userInfo.token}`,
  //       },
  //     });
  //     // setImage(data);
  //     // dispatch({ type: 'UPLOAD_SUCCESS' });
  //   } catch (error) {
  //     // toast.error(getError(error));
  //     // dispatch({ type: 'UPLOAD_FAIL' });
  //   }
  // };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ images: event.target.files[0] });
  };

  // On file upload (click the upload button)
  onFileUpload = async () => {
    // const home = () => {
    //   window.open('/', '_self');
    // };
    // Create an object of formData
    var formData = new FormData();
    // formData.append(data, this.props.values);
    formData.append('images', this.state.images, this.state.images.name);
    // // Details of the uploaded file
    console.log(this.state.images);
    // // return formData;
  };

  onSubmit = (e) => {
    e.preventDefault();
    const eventId = window.location.href.split('/')[4];
    const eventDetailPage = () => {
      window.open('/show-event/' + eventId, '_self');
    };

    const data = {
      eventTitle: this.state.eventTitle,
      eventOrganizerName: this.state.eventOrganizerName,
      // images: this.state.images,
      eventLocation: this.state.eventLocation,
      eventCity: this.state.eventCity,
      eventState: this.state.eventState,
      eventZip: this.state.eventZip,
      eventCountry: this.state.eventCountry,
      eventDate: this.state.eventDate,
      eventTime: this.state.eventTime,
      eventType: this.state.eventType,
      createdDate: this.state.createdDate,
      publishedDate: this.state.publishedDate,
      eventCost: this.state.eventCost,
      eventCostAmount: this.state.eventCostAmount,
      organizerEmail: this.state.organizerEmail,
      organizerPhone: this.state.organizerPhone,
      eventAdditionalInfo: this.state.eventAdditionalInfo,
      eventMain: this.state.eventMain,
      eventSponsored: this.state.eventSponsored,
      eventSponsoredBy: this.state.eventSponsoredBy,
    };

    console.log('test edit event');
    // const saveTodb = () => {
    var formData = new FormData();

    // Update the formData object
    // formData.append('images', this.state.images, this.state.images.name);

    // Details of the uploaded file
    console.log(this.state.images);
    // var data = this.props.values;
    const data1 = this.props.values;
    console.log(data1);
    const data2 = this.props.state;
    console.log(data2);
    const _id = currentUser.id;
    // var formData = new FormData();
    formData = { data, userId: _id, images: this.state.images };
    // formData.append('images', this.state.images, this.state.images.name);

    console.log(this.state.images);
    console.log(formData);
    // formData.append('images', Event.images);
    // formData.append(...data, data);
    // console.log(formData['file']);
    // const home = () => {
    //   window.open('/', '_self');
    // };

    axios
      .put(
        'https://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/' +
          eventId,
        { headers: authHeader() },
        formData
        // {
        //   headers: {
        //     'Content-Type': 'multipart/form-data',
        //     Authorization: `Bearer ${currentUser.accessToken}`,
        //   },
        // }
      )

      // axios
      //   .put(
      //     // 'http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/' + this.props.match.params.id,
      //     'http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/' + eventId,
      //     data
      //   )
      .then((res) => {
        // this.props.history.push('/show-event/' + this.props.match.params.id);
        eventDetailPage();
      })
      .catch((err) => {
        console.log('Error in UpdateEventInfo!');
      });
  };

  render() {
    const { classes, upload } = this.props;
    return (
      <Grid>
        <form noValidate onSubmit={this.onSubmit}>
          <Card className={classes.card}>
            <TextField
              placeholder="Enter Your Event Title"
              label="Event Title"
              value={this.state.eventTitle}
              onChange={this.onChange}
              name="eventTitle"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Event Organizer Name"
              label="Event Organizer Name"
              value={this.state.eventOrganizerName}
              onChange={this.onChange}
              name="eventOrganizerName"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Event Organizer Email"
              label="Event Organizer Email"
              value={this.state.organizerEmail}
              onChange={this.onChange}
              name="organizerEmail"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Event Organizer Phone Number"
              label="Event Organizer Phone Number"
              value={this.state.organizerPhone}
              onChange={this.onChange}
              margin="normal"
              name="organizerPhone"
              fullWidth
            />
            <br />
            <label htmlFor="contained-button-file">
              <Input
                type="file"
                multiple
                label="Event Flyer or Image"
                name="images"
                onChange={this.onFileChange.bind(this)}
                defaultValue={this.images}
                margin="normal"
                fullWidth
              />
              <Button
                className={classes.button}
                color="primary"
                name="images"
                variant="contained"
                // onClick={this.onClick.bind(this)}
                onClick={this.onFileUpload.bind(this)}
              >
                Upload
              </Button>
            </label>
            <br />

            <TextField
              select
              placeholder="Enter Your Event Type"
              label="Event Type"
              value={this.state.eventType}
              onChange={this.onChange}
              name="eventType"
              margin="normal"
              fullWidth
            >
              {eventTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            <br />
            <div className="form-group">
              <input
                type="date"
                placeholder="Enter Your Event Date"
                label="Event Date"
                name="eventDate"
                className="form-control"
                value={this.state.eventDate}
                onChange={this.onChange}
              />
            </div>
            <br />
            <div className="form-group">
              <input
                type="time"
                placeholder="Enter Your Event Time"
                label="Event Time"
                name="eventTime"
                className="form-control"
                value={this.state.eventTime}
                onChange={this.onChange}
              />
            </div>
            <br />
            <TextField
              select
              placeholder="Enter Your Event Cost"
              label="Event Cost"
              value={this.state.eventCost}
              onChange={this.onChange}
              margin="normal"
              name="eventCost"
              fullWidth
            >
              {eventCost.map((option) => (
                <MenuItem
                  key={option.value}
                  // selected={option === 'FREE'}
                  value={option.value}
                >
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            {/* <br /> */}
            {/* {console.log(values.eventCost)} */}
            <br />
            <div
              style={{
                display: this.state.eventCost !== 'Free' ? 'block' : 'none',
              }}
            >
              <TextField
                // Component={ToggleableText}
                placeholder="Enter Your Event Cost Amount"
                label="Event Cost Amount"
                value={this.state.eventCostAmount}
                onChange={this.onChange}
                margin="normal"
                name="eventCostAmount"
                fullWidth
              />
            </div>

            <br />
            <TextField
              placeholder="Enter Your Event Location"
              label="Event Location"
              value={this.state.eventLocation}
              onChange={this.onChange}
              name="eventLocation"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Event Address"
              label="Event Address"
              value={this.state.eventAddress}
              onChange={this.onChange}
              name="eventAddress"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Event Detail Information"
              label="Event Detail Information"
              value={this.state.eventAdditionalInfo}
              onChange={this.onChange}
              name="eventAdditionalInfo"
              margin="normal"
              fullWidth
            />
            {/* <br />
            <TextField
              placeholder="Enter Event City"
              label="Event City"
              value={this.state.eventCity}
              onChange={this.onChange}
              name="eventCity"
              margin="normal"
              fullWidth
            /> */}
            <br />
            <TextField
              placeholder="Enter Your City"
              label="City"
              value={this.state.eventCity}
              onChange={this.onChange}
              name="eventCity"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Event State"
              label="Event State"
              value={this.state.eventState}
              onChange={this.onChange}
              name="eventState"
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Event Zip"
              label="Event Zip"
              value={this.state.eventZip}
              name="eventZip"
              onChange={this.onChange}
              margin="normal"
              fullWidth
            />
            <br />
            <TextField
              placeholder="Enter Your Event Country"
              label="Event Country"
              value={this.state.eventCountry}
              onChange={this.onChange}
              name="eventCountry"
              margin="normal"
              fullWidth
            />
            <br />
          </Card>
          <br />
          <br />

          <button
            type="submit"
            className="btn btn-outline-info btn-lg btn-block"
          >
            Update Event
          </button>
        </form>
      </Grid>
    );
  }
}

// export default UpdateEventInfo_;
export default withStyles(useStyles)(UpdateEventInfo_);
