import React, { Component } from 'react';
// import AppBar from '@material-ui/core/AppBar';
// import AppBar from '@mui/material/AppBar';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Input } from '@material-ui/core/';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import AuthService from '../../services/auth.service';

const styles = (theme) => ({
  card: {
    marginTop: '5px',
    padding: '30px',
    scrollPaddingBlockEnd: true,
    marginBottom: '20px',
    spacing: '10px',
  },
  button: {
    paddingLeft: '10px',
    marginLeft: '10px',
  },
  actions: {
    display: 'flex',
  },
  expand: {
    transform: 'rotate(0deg)',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
    marginLeft: 'auto',
    [theme.breakpoints.up('sm')]: {
      marginRight: -8,
    },
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: 'red',
  },
});

const currentUser = AuthService.getCurrentUser();

export class Confirm extends Component {
  continue = (e) => {
    e.preventDefault();
    // PROCESS FORM //
    this.props.nextStep();
  };

  doSomething = () => {
    console.log('test');
  };

  doSomething2 = () => {
    // Function Scope
    console.log('test2');
  };
  onClick = (e) => {
    console.log('test onClick');
    const saveTodb = () => {
      var formData = new FormData();

      // Update the formData object
      // formData.append('images', this.state.images, this.state.images.name);

      // Details of the uploaded file
      console.log(this.state.images);
      const data = this.props.values;
      const data1 = this.props.values;
      console.log(data1);
      const data2 = this.props.state;
      console.log(data2);
      const _id = currentUser.id;
      // var formData = new FormData();
      formData = { data, userId: _id, images: this.state.images };
      // formData.append('images', this.state.images, this.state.images.name);

      console.log(this.state.images);
      console.log(formData);
      // formData.append('images', Event.images);
      // formData.append(...data, data);
      // console.log(formData['file']);
      const home = () => {
        window.open('/', '_self');
      };

      // await axios.post('http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/', formData, {
      //   headers: {
      //     'Content-Type': 'multipart/form-data',
      //     // Authorization: `Bearer ${userInfo.token}`,
      //   },
      // });

      axios
        .post(
          'https://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${currentUser.accessToken}`,
            },
          }
        )
        // .then((res) => {
        //   console.log(res);
        //   console.log(res.data);
        // })
        .then((res) => {
          this.setState({
            eventTitle: '',
            eventOrganizerName: '',
            images: '',
            eventLocation: '',
            eventAddress: '',
            eventCity: '',
            eventState: '',
            eventZip: '',
            eventCountry: '',
            eventDate: '',
            eventTime: '',
            eventType: '',
            // createdDate: '',
            // publishedDate: '',
            eventCost: '',
            eventCostAmount: '',
            organizerEmail: '',
            organizerPhone: '',
            eventAdditionalInfo: '',
            eventMain: '',
            eventSponsored: '',
            eventSponsoredBy: '',
          });
          // this.props.history.push('/');
          home();
          // this.props.navigation.navigate('ScreenB');
        })
        .catch((err) => {
          console.log('Error in Event No Created!');
        });
    };
    const next = () => {
      e.preventDefault();
      // PROCESS FORM //
      this.props.nextStep();
    };

    saveTodb();
    next();
  };

  // state = {
  //   // Initially, no file is selected
  //   images: null,
  // };

  // On file select (from the pop up)
  onFileChange = (event) => {
    // Update the state
    this.setState({ images: event.target.files[0] });
  };

  // On file upload (click the upload button)
  onFileUpload = async () => {
    const home = () => {
      window.open('/', '_self');
    };
    // Create an object of formData
    var formData = new FormData();
    // const data = this.props.values;
    // formData = data;

    // Update the formData object

    // formData.append(data, this.props.values);
    formData.append('images', this.state.images, this.state.images.name);
    // formData.append('userId', currentUser);
    // formData.append('eventTitle', this.props.eventTitle);
    // formData.append('eventOrganizerName', this.props.eventOrganizerName);
    // formData.append('eventDate', this.props.eventDate);
    // formData.append('eventTime', this.props.eventTime);
    // formData.append('eventAdditionalInfo', this.props.eventAdditionalInfo);
    // formData.append('eventCountry', this.props.eventCountry);

    // // Details of the uploaded file
    console.log(this.state.images);
    // // return formData;

    // // Request made to the backend api
    // // Send formData object
    // // axios.post('api/uploadfile', formData);
    // await axios
    //   .post('http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/events/', formData, {
    //     headers: {
    //       'Content-Type': 'multipart/form-data',
    //       // Authorization: `Bearer ${userInfo.token}`,
    //     },
    //   })
    //   .then((res) => {
    //     this.setState({
    //       eventTitle: '',
    //       eventOrganizerName: '',
    //       images: '',
    //       eventLocation: '',
    //       eventAddress: '',
    //       eventCity: '',
    //       eventState: '',
    //       eventZip: '',
    //       eventCountry: '',
    //       eventDate: '',
    //       eventTime: '',
    //       eventType: '',
    //       // createdDate: '',
    //       // publishedDate: '',
    //       eventCost: '',
    //       eventCostAmount: '',
    //       organizerEmail: '',
    //       organizerPhone: '',
    //       eventAdditionalInfo: '',
    //       eventMain: '',
    //       eventSponsored: '',
    //       eventSponsoredBy: '',
    //     });
    //     // this.props.history.push('/');
    //     home();
    //   })
    //   .catch((err) => {
    //     console.log('Error in Event No Created!');
    //   });
    // const next = (e) => {
    //   e.preventDefault();
    //   // PROCESS FORM //
    //   this.props.nextStep();
    // };

    // // saveTodb();
    // next();
    // // this.setState('image');
  };

  upload = () => (e) => {
    var data = new FormData();
    data.append('image', e.target.files[0]);
    // data.append('username', 'Saurabh'); //if you have other fields

    // axios.post('/api/upload/uploadImage', data)
    axios
      .post('/', data)
      //  headers: {
      //   //your headers
      //  })
      .then((response) => {
        alert(JSON.stringify(response));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // onSubmit = (e) => {
  //   e.preventDefault();
  //   const home = () => {
  //     window.open('/', '_self');
  //   };

  //   const data = {
  //     eventTitle: this.state.eventTitle,
  //     eventOrganizerName: this.state.eventOrganizerName,
  //     images: this.state.images,
  //     eventLocation: this.state.eventLocation,
  //     eventCity: this.state.eventCity,
  //     eventState: this.state.eventState,
  //     eventZip: this.state.eventZip,
  //     eventCountry: this.state.eventCountry,
  //     eventDate: this.state.eventDate,
  //     eventTime: this.state.eventTime,
  //     eventType: this.state.eventType,
  //     // createdDate: this.state.createdDate,
  //     // publishedDate: this.state.publishedDate,
  //     eventCost: this.state.eventCost,
  //     eventCostAmount: this.state.eventCostAmount,
  //     organizerEmail: this.state.organizerEmail,
  //     organizerPhone: this.state.organizerPhone,
  //     eventAdditionalInfo: this.state.eventAdditionalInfo,
  //     eventMain: this.state.eventMain,
  //     eventSponsored: this.state.eventSponsored,
  //     eventSponsoredBy: this.state.eventSponsoredBy,
  //   };

  //   axios
  //     .post('http://bilili-env.eba-pnmzuzid.us-east-1.elasticbeanstalk.com/api/Events', data)
  //     .then((res) => {
  //       this.setState({
  //         eventTitle: '',
  //         eventOrganizerName: '',
  //         images: '',
  //         eventLocation: '',
  //         eventAddress: '',
  //         eventCity: '',
  //         eventState: '',
  //         eventZip: '',
  //         eventCountry: '',
  //         eventDate: '',
  //         eventTime: '',
  //         eventType: '',
  //         // createdDate: '',
  //         // publishedDate: '',
  //         eventCost: '',
  //         eventCostAmount: '',
  //         organizerEmail: '',
  //         organizerPhone: '',
  //         eventAdditionalInfo: '',
  //         eventMain: '',
  //         eventSponsored: '',
  //         eventSponsoredBy: '',
  //       });
  //       // this.props.history.push('/');
  //       home();
  //       this.upload();
  //       // this.props.navigation.navigate('ScreenB');
  //     })
  //     .catch((err) => {
  //       console.log('Error in CreateEvent!');
  //     });
  // };

  back = (e) => {
    e.preventDefault();
    this.props.prevStep();
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const data = this.props.values;
    console.log(data);
    // const user = {
    //   name: this.state.name
    // };

    // axios.post(`https://jsonplaceholder.typicode.com/users`, { user })
    //   .then(res => {
    //     console.log(res);
    //     console.log(res.data);
    //   })
  };

  render() {
    const {
      values: {
        eventTitle,
        eventOrganizerName,
        images,
        eventLocation,
        eventAddress,
        eventCity,
        eventState,
        eventZip,
        eventCountry,
        eventDate,
        eventTime,
        eventType,
        // createdDate,
        // publishedDate,
        eventCost,
        eventCostAmount,
        organizerEmail,
        organizerPhone,
        eventAdditionalInfo,
        eventMain,
        eventSponsored,
        eventSponsoredBy,
      },
      classes,
      values,
    } = this.props;
    return (
      <MuiThemeProvider>
        <>
          <form onSubmit={this.handleSubmit}>
            <Grid>
              <Card className={classes.card}>
                <List>
                  <ListItem>
                    <ListItemText
                      primary="Event Title"
                      secondary={eventTitle}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event Organizer Name"
                      secondary={eventOrganizerName}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Event Image" secondary={images} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event Organizer Email"
                      secondary={organizerEmail}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event Organizer Phone"
                      secondary={organizerPhone}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event Location"
                      secondary={eventLocation}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event Address"
                      secondary={eventAddress}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event Additional Info"
                      secondary={eventAdditionalInfo}
                    />
                  </ListItem>
                  <label htmlFor="contained-button-file">
                    <Input
                      type="file"
                      multiple
                      label="Event Flyer or Image"
                      name="images"
                      onChange={this.onFileChange.bind(this)}
                      defaultValue={values.images}
                      margin="normal"
                      fullWidth
                    />
                    <Button
                      className={classes.button}
                      color="primary"
                      name="images"
                      variant="contained"
                      // onClick={this.onClick.bind(this)}
                      onClick={this.onFileUpload.bind(this)}
                    >
                      Upload
                    </Button>
                  </label>
                  <ListItem>
                    <ListItemText primary="Event Date" secondary={eventDate} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Event Time" secondary={eventTime} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Event Type" secondary={eventType} />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Event Cost" secondary={eventCost} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event Cost Amount"
                      secondary={eventCostAmount}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Event City" secondary={eventCity} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event State"
                      secondary={eventState}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText primary="Event Zip" secondary={eventZip} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event Country"
                      secondary={eventCountry}
                    />
                  </ListItem>
                  {/* <ListItem>
                    <ListItemText
                      primary="Created Date"
                      secondary={createdDate}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Published Date"
                      secondary={publishedDate}
                    />
                  </ListItem> */}
                  <ListItem>
                    <ListItemText primary="Event Main" secondary={eventMain} />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event Sponsored"
                      secondary={eventSponsored}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemText
                      primary="Event Sponsored By"
                      secondary={eventSponsoredBy}
                    />
                  </ListItem>
                </List>
                <br />
              </Card>
            </Grid>

            <Button color="secondary" variant="contained" onClick={this.back}>
              Back
            </Button>

            <Button
              className={classes.button}
              color="primary"
              variant="contained"
              onClick={this.onClick}
            >
              Confirm & Continue
            </Button>
          </form>
        </>
      </MuiThemeProvider>
    );
  }
}

export default withStyles(styles)(Confirm);
