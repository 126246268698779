export const validateLoginForm = ({ email, password }) => {
  const isMailValid = validateMail(email);
  const isPasswordValid = validatePassword(password);

  return isMailValid && isPasswordValid;
};

export const validateRegisterForm = ({ email, password, username }) => {
  return (
    validateMail(email) &&
    validatePassword(password) &&
    validateUsername(username)
  );
};

const validatePassword = (password) => {
  return password.length > 5 && password.length < 13;
};

const validateMail = (email) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

const validateUsername = (username) => {
  return username.length > 2 && username.length < 13;
};

export const validateCreateEventForm = ({
  eventTitle,
  eventOrganizerName,
  eventLocation,
  eventDate,
  eventTime,
  eventType,
  createdDate,
  publishedDate,
  eventCost,
  organizerEmail,
  organizerPhone,
  eventAdditionalInfo,
  eventMain,
  eventSponsored,
  eventSponsoredBy,
}) => {
  return (
    validateEventTitle(eventTitle) &&
    validateEventOrganizerName(eventOrganizerName) &&
    validateEventLocation(eventLocation) &&
    validateEventDate(eventDate) &&
    validateEventTime(eventTime) &&
    validateEventType(eventType) &&
    validateCreatedDate(createdDate) &&
    validatePublishedDate(publishedDate) &&
    validateEventCost(eventCost) &&
    validateOrganizerEmail(organizerEmail) &&
    validateOrganizerPhone(organizerPhone) &&
    validateEventAdditionalInfo(eventAdditionalInfo) &&
    validateEventMain(eventMain) &&
    validateEventSponsored(eventSponsored) &&
    validateEventSponsoredBy(eventSponsoredBy)
  );
};

const validateEventSponsoredBy = (eventSponsoredBy) => {
  return eventSponsoredBy.length > 5 && eventSponsoredBy.length < 100;
};

const validateEventSponsored = (eventSponsored) => {
  return eventSponsored.length > 5 && eventSponsored.length < 100;
};

const validateEventMain = (eventMain) => {
  return eventMain.length > 5 && eventMain.length < 100;
};

const validateOrganizerPhone = (organizerPhone) => {
  return organizerPhone.length > 5 && organizerPhone.length < 100;
};

const validateEventAdditionalInfo = (eventAdditionalInfo) => {
  return eventAdditionalInfo.length > 5 && eventAdditionalInfo.length < 500;
};

const validateEventCost = (eventCost) => {
  return eventCost.length > 5 && eventCost.length < 100;
};

const validatePublishedDate = (publishedDate) => {
  return publishedDate.length > 5 && publishedDate.length < 100;
};

const validateCreatedDate = (createdDate) => {
  return createdDate.length > 5 && createdDate.length < 100;
};

const validateEventType = (eventType) => {
  return eventType.length > 5 && eventType.length < 100;
};

const validateEventTime = (eventTime) => {
  return eventTime.length > 5 && eventTime.length < 100;
};

const validateEventLocation = (eventLocation) => {
  return eventLocation.length > 5 && eventLocation.length < 100;
};

const validateEventDate = (eventDate) => {
  return eventDate.length > 5 && eventDate.length < 100;
};

const validateEventTitle = (eventTitle) => {
  return eventTitle.length > 5 && eventTitle.length < 100;
};

const validateOrganizerEmail = (organizerEmail) => {
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(organizerEmail);
};

const validateEventOrganizerName = (eventOrganizerName) => {
  return eventOrganizerName.length > 2 && eventOrganizerName.length < 13;
};
